import React from 'react';
import BgImageMedia from './BgImageMedia';
import BgVideoMedia from './BgVideoMedia';
import BgColor from './BgColor';
import { Tab, Nav } from "react-bootstrap";
import Gifs from './Gifs';
import UploadMedia from './UploadMedia';

const BackgroundMedia = ({ slideData }) => {
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")

    return (
        <div className="tabInner">
            <div className='tabTitle'>Background</div>
            <div className="media-block">
                <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                    <Nav variant="pills" className="inTabNav">
                        <Nav.Item>
                            <Nav.Link eventKey="image-tab">Images</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="video-tab">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="color-tab">Color</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="gif-tab">Gifs</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="image-tab">
                            <BgImageMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="video-tab">
                            <BgVideoMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />

                        </Tab.Pane>
                        <Tab.Pane eventKey="color-tab">
                            <BgColor
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="gif-tab">
                            <Gifs
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-tab">
                            <UploadMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedSlide={selectedSlide}
                            />
                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};


export default BackgroundMedia;

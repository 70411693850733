
import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { BsFillMicFill, BsFillMicMuteFill, BsLink } from 'react-icons/bs'
import { FiMusic, FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import { AiFillPlusCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { deleteBackTile, deleteTile, onAddSlide, onCheckInnerStatus, onRemovePlaySlide, onSelectInnerBackground, rearrangeTiles, removeTtsFromSlides, updateScript } from '../../../actions/contentAction'
import Swal from 'sweetalert2'
import { FaSpinner } from 'react-icons/fa'
// import SweetAlert from '../../CommonComponents/SweetAlert'
import SweetAlert from "react-bootstrap-sweetalert";
import { setAlert } from '../../../actions/alert'


import { IoPauseOutline } from "react-icons/io5";


import { IoMicOffOutline, IoMicOutline, IoTrashOutline } from 'react-icons/io5'
import { RxText } from 'react-icons/rx'
import { themeColor } from '../../../global/global'
import SlideAudio from './SlideAudio'
import BackgroundAudio from './BackgroundAudio'
import Tile from './Tile'


const VideoTiles = ({ generatingText }) => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.content.campaign)
    const editor = useSelector(state => state.editor)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [basic, setBasic] = useState({
        totalDuration: 200
    })
    const [addLoader, setAddLoader] = useState(false)

    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        id: -1,
        button: "Delete",
        name: "",
        show: false,
        loader: false
    })

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    const onConfirm = () => {
        if (state.slideIndex !== undefined) {
            setState({
                ...state,
                button: "Deleting",
                loader: true
            })
            dispatch(deleteBackTile(state.slideIndex, state.backIndex, setState))
        } else {
            if (campaign.slides.length === 1) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You cannot delete last slide!',
                    confirmButtonColor: "#00D0B0"
                })
                setState({
                    id: -1,
                    button: "Delete",
                    show: false,
                    name: "",
                    loader: false
                })
            } else {
                setState({
                    ...state,
                    button: "Deleting",
                    loader: true
                })
                dispatch(deleteTile(state.slide, state.index, setState))
            }
        }
    }
    const onCancel = () => {
        setState({
            id: -1,
            button: "Delete",
            show: false,
            name: "",
            loader: false
        })
    }
    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        } else if (result.source.index === result.destination.index) {
            return;
        } else {
            // dispatch(rearrangeTiles(result.source.index, result.destination.index));
        }
        const items = reorder(campaign.slides, result.source.index, result.destination.index);
        dispatch(rearrangeTiles(items));
    }

    const onSelectTile = (index) => {
        if (index !== selectedSlideIndex) {
            dispatch(updateScript(index, selectedSlideIndex))
            dispatch(onRemovePlaySlide(selectedSlideIndex))
        }
    }

    const selectInnerBackground = (index, ind) => {
        if (+campaign.slides[index].background[ind].isSelected !== 1) {
            dispatch(onSelectInnerBackground(index, ind))
        }
    }

    const handleDeleteAlert = (e, slide, index) => {
        e.stopPropagation()
        setState({
            ...state,
            show: true,
            name: "tile",
            slide: slide,
            index: index
        })
    }

    const handleDelete = (e, slideindex, backIndex) => {
        e.stopPropagation()
        setState({
            ...state,
            show: true,
            name: "background",
            slideIndex: slideindex,
            backIndex: backIndex
        })
    }


    const handleAddSlide = () => {
        if (!addLoader) {
            if (editor.voiceOver.isGenerating || editor.voiceClone.processLoader) {
            } else {
                let obj = {
                    videoId: campaign.id,
                }
                if (campaign.slides.length > 20) {
                    dispatch(setAlert("You can create max 20 slides!", "danger"))
                } else {
                    setAddLoader(true)
                    dispatch(onAddSlide(obj, setAddLoader))
                }
            }
        }
    }


    const handleTts = (slideIndex, e) => {
        e.stopPropagation()
        dispatch(removeTtsFromSlides(slideIndex))
    }

    useEffect(() => {
        if (campaign.slides.length > 0) {
            let dur = 0

            campaign.slides.forEach((camp) => {
                dur += +camp.duration
            })

            setBasic({
                ...basic,
                totalDuration: parseInt(dur)
            })
        }

    }, [campaign])

    return (
        <>
            <div className="video-ruler" style={{ display: "inline-flex", }}>
                {basic.totalDuration > 0 ?
                    new Array(basic.totalDuration + 10).fill(1).map((curElem, index) => {
                        return (
                            <>
                                <div className="ruler-single" style={{ width: 100 }}><span>{index}</span></div>
                            </>
                        )
                    })

                    : ""}
                <div className="addSlide">
                    <div className="addSlide-in" onClick={handleAddSlide} style={{ background: editor.voiceOver.isGenerating || editor.voiceClone.processLoader ? "gray" : "" }}>
                        {
                            addLoader ?
                                <FaSpinner className='spin' /> :
                                <>
                                    <span>Add  <FiPlusCircle /></span>
                                </>
                        }
                    </div>
                </div>
            </div>

            <DragDropContext onDragEnd={onSetRecorder}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                        <div
                            className="videoSlide"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                display: "inline-flex", width: +basic.totalDuration * 100,
                                // position: "relative", height: 34
                            }}
                        >
                            {campaign.slides.length > 0 ?
                                campaign.slides.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Tile
                                                curElem={curElem}
                                                index={index}
                                                handleTts={handleTts}
                                                onSelectTile={onSelectTile}
                                                selectInnerBackground={selectInnerBackground}
                                                handleDeleteAlert={handleDeleteAlert}
                                                handleDelete={handleDelete}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : ""}
                            {generatingText ?
                                <div
                                    className={`videoSlide-group text-light align-items-center`}
                                    style={{
                                        // width: 100,
                                        height: "3.1875rem"
                                    }}
                                >
                                    {generatingText}<FaSpinner className='spin' style={{ color: themeColor, fontSize: 25 }} />
                                </div> : null}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>


            <div className="audioSlide" style={{ width: +basic.totalDuration * 100 }}>
                {campaign.slides.length > 0 ?
                    campaign.slides.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <SlideAudio
                                    curElem={curElem}
                                    index={index}
                                />
                            </React.Fragment>
                        )
                    })
                    : ""}
            </div>

            <div className="audioSlide" style={{ width: +basic.totalDuration * 100 }}>
                <BackgroundAudio
                    campaign={campaign}
                    basic={basic}
                />
            </div>


            {/* <div className="audioSlide">
                <div className="audioSlide-single">
                    <div className="audio-meta">
                        <div className="audio-meta-left">
                            <div className="audio-meta-icon"><RxText /></div>
                            <span>SubTitle - 1</span>
                        </div>
                        <div className="audio-meta-right">0.32</div>
                    </div>
                </div>
            </div> */}

            {/* <SweetAlert
                show={state.enable}
                message={"Are you sure you want to delete this tile?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                button={state.button}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
                alertLoader={state.loader}
            /> */}
            <SweetAlert
                warning
                showCancel
                confirmBtnText={state.button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={onConfirm}
                onCancel={onCancel}
                focusCancelBtn
                show={state.show}
            >
                <div style={{ color: "gray", fontWeight: "bold" }}>
                    {`you want to delete this  ${state.name}?`}
                </div>
            </SweetAlert>

        </>

    )
}

export default VideoTiles

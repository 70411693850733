import {combineReducers} from "redux";
import authReducer from "./authReducer";
import alertReducer from "./alertReducer";
import supportReducer from "./supportReducer";
import rebrandReducer from "./rebrandReducer";
import socialReducer from "./socialReducer";
import contentReducer from "./contentReducer";
import { EditorReducer } from "./EditorReducer";

const appReducer = combineReducers({
    alert: alertReducer,
    auth: authReducer,
    support: supportReducer,
    rebrand: rebrandReducer,
    social: socialReducer,
    content: contentReducer,
    editor: EditorReducer
})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;
import React from "react";
import EditTeamClient from "./EditTeamClient";
import { themeColor } from "../../global/global";

const ManageClient = ({ users, userLoader, fetchClientTeam, type }) => {

    return (
        <>
            <div className="profileBlock-wrap profileBlock-wrap-last">
                <h2 className="infoTitle">Manage {type ? type : 'Client'} Accounts </h2>
                <div className="profileBlock tableWrap mt-0">
                    <div className="table-responsive">
                        <table className="table table-striped table-dark">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email Address</th>
                                    <th scope="col">Created</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.length > 0 ?
                                        users.map((user, index) => {
                                            return (
                                                <>
                                                    <EditTeamClient
                                                        type={`${type ? type : 'Client'} Account`}
                                                        user={user}
                                                        index={index}
                                                        fetchClientTeam={fetchClientTeam}
                                                        key={`client_${index}`}
                                                    />
                                                </>
                                            )
                                        })
                                        : <tr className='text-center'>
                                            <td colSpan={4}>
                                                {
                                                    userLoader ?
                                                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 20, color: themeColor }} />
                                                        : "You have not created any client yet!"
                                                }

                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageClient;
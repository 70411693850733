import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoCard from "../project/VideoCard";

const DashboardContent = ({ setCampLength }) => {
    const [project, setProject] = useState([])
    return (
        <>

            <section className="videoProject">
                <div className="container-fluid">
                    <div className="videoProject-top">
                        <h2>Recent <span>Campaigns</span></h2>
                        <Link to="/campaigns" className="proView">View all <span><i class="fa fa-angle-right" aria-hidden="true"></i></span> </Link>
                    </div>
                    <div className="videoProject-bottom">
                        <VideoCard
                            project={project}
                            setProject={setProject}
                            showRecord={4}
                            setTotalCourses={setCampLength}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardContent;
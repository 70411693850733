export const onPlayEditorMedia = (url) => (dispatch) => {
    dispatch({ type: "ON_PLAY_MEDIA_EDITOR", payload: url })
}

export const disableDelete = () => (dispatch) => {
    dispatch({ type: "DISABLE_DELETE" })
}

export const enableDelete = () => (dispatch) => {
    dispatch({ type: "ENABLE_DELETE" })
}

export const generationDisableTTS = () => (dispatch) => {
    dispatch({ type: "GENERATING_TTS" })
}

export const generationEnableTTS = () => (dispatch) => {
    dispatch({ type: "GENERATION_DONE" })
}

export const activeCloneLoader = () => (dispatch) => {
    dispatch({ type: "CLONE_LOADER_ACTIVE" })
}

export const deactiveCloneLoader = () => (dispatch) => {
    dispatch({ type: "CLONE_LOADER_DEACTIVE" })
}
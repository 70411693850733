import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert'
import { getUploadData, onDeleteMedia, onUploadAudio } from '../../../actions/contentAction';
import Alert from '../../Alert'
import AudioFiles from "../AudioFiles"
import { themeColor } from '../../../global/global';

const UploadVoice = ({ selectedSlideIndex, type }) => {
    let allowedExt = ['audio/mpeg'];
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [fetchLoader, setFetchLoader] = useState({
        fetch : true
    })
    const [audio, setAudio] = useState([])
    const [selectedAudio, setSelectedAudio] = useState(false)
    const [currentUrl, setCurrentUrl] = useState('');

    const handleCall = (e, config) => {
        const formData = new FormData()
        formData.append('upload_type', type)
        formData.append('file', e.target.files[0])
        dispatch(onUploadAudio(formData, setLoader, setPercent, fetchAudios, type, config))
    }

    const onInputFile = (e) => {
        const config = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                setPercent(percent);
            },
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.token
            },
        }
        let allowedSize = 5000000
        if (allowedExt.includes(e.target.files[0].type)) {
            if (e.target.files[0].size < allowedSize) {
                setLoader(true)
                handleCall(e, config)
            } else {
                swal("Oops!", "Max allowed size for Music File is 5MB");
            }
        }
        else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }
    const fetchAudios = () => {
        let data = {
            type: type
        }
        dispatch(getUploadData(data, setAudio, fetchLoader, setFetchLoader))
    }

    const removeMedia = (id, setButtonText, setShowDelete) => {
        let formData = new FormData();
        formData.append('id', id);
        dispatch(onDeleteMedia(formData, setShowDelete, setButtonText, fetchAudios))
    }


    useEffect(() => {
        fetchAudios()
    }, [])

    return (
        <div className='row'>
            <Alert />
            <div className='col-md-12 mt-4'>
                <div className="tabSearch">
                    <button className="demoLink alt btn-block mt-0 text-capitalize"><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload {type}</button>
                    <input className="absInp" type="file"
                        onChange={(e) => onInputFile(e)}
                    />
                </div>
                {
                    loader ?
                        <div className="progress mt-3">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${percent}%`, background: "linear-gradient(to left, #C16BED 0%, #3DAEC7 100%)" }}
                                aria-valuenow={`${percent}`}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {percent}%
                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="innertab-scroll" id="scrollableMediaImage" >
                <div className="row">
                    <div className="mediaList forAudio">
                        <ul>
                            {
                                audio.length > 0 ?
                                    audio.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <AudioFiles
                                                    voiceOverName={curElem.name.length > 15 ? `${curElem.name.slice(0, 14)}...` : curElem.name}
                                                    title={type === "music" ? curElem.name.length > 15 ? `${curElem.name.slice(0, 14)}...` : curElem.name : "Uploaded VoiceOver"}
                                                    selectedSlideIndex={selectedSlideIndex}
                                                    url={curElem.url}
                                                    type="uploaded"
                                                    removeMedia={removeMedia}
                                                    selectedAudio={selectedAudio}
                                                    setSelectedAudio={setSelectedAudio}
                                                    currentUrl={currentUrl}
                                                    setCurrentUrl={setCurrentUrl}
                                                    key={index}
                                                    id={curElem.id}
                                                />
                                            </React.Fragment>
                                        )
                                    }) :
                                    fetchLoader.fetch ?
                                        <div className="col-md-12">
                                            <h4 className="text-center"><p style={{ color: themeColor }}>No Data Found</p></h4>
                                        </div>
                                        : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadVoice
import React, { useEffect, useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { onUploadMedia } from '../../actions/CommonActions'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import dummmyImg from "../../images/dummy.jpg"

const CreateTalkingHead = ({ state, setState, handleShow }) => {
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const dispatch = useDispatch()
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false
    })

    const [freqMessage, setFreqMessage] = useState({
        color: "",
        message: ""
    })

    const onSuccessUpload = (url, type, thumb) => {
        setState((draft) => {
            draft.faceNarration.media.url = url
        })
    }



    const handleChange = (e) => {
        const { name, value, checked } = e.target
        if (name === "coverage") {
            setState((draft) => {
                draft.faceNarration.coverage = value
            })

        } else if (name === "headMovement") {
            setState((draft) => {
                draft.faceNarration.headMovement = checked
            })
        }

    }

    const handlePositons = (type) => {
        setState((draft)=>{
            draft.faceNarration.media.pos = type
        })
    }

    const handleShape = (type) => {
        setState((draft)=>{
            draft.faceNarration.media.shape = type
        })
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, onSuccessUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: "#00D0B0"
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: "#00D0B0"
                })
            }
        }
    }

    console.log(state.faceNarration.media)

    useEffect(() => {
        if (state) {
            if (+state.faceNarration.coverage === 10) {
                setFreqMessage({
                    ...freqMessage,
                    message: "Video Rendering will take normal time.",
                    color: "success"
                })
            } else if (+state.faceNarration.coverage === 25) {
                setFreqMessage({
                    ...freqMessage,
                    message: "Rendering will take slightly more time then normal.",
                    color: "warning"
                })
            } else if (+state.faceNarration.coverage === 50) {
                setFreqMessage({
                    ...freqMessage,
                    message: "Rendering will take longer time then normal.",
                    color: "danger"
                })
            }
        }
    }, [state])


    return (
        <>
            <div className="sad_talker_img mt-3 ">
                <img src={state.faceNarration.media.url ? state.faceNarration.media.url : dummmyImg} alt="" />
            </div>
            <p className="cursor-pointer py-2 text-center" style={{ fontSize: 13 }} onClick={handleShow}>What type of image should I upload?</p>

            <div className='upload-box'>
                <div className="fileUpload alt d-block text-center">
                    {loader.upload ?
                        <div className='percent-loader-box'>
                            <span className='loader-percent'>{percent}%</span>
                        </div>
                        :
                        <>
                            <FiUpload />
                            <p>Upload image here</p>
                            <input
                                type="file"
                                onChange={onInputChange}
                            />
                        </>
                    }
                </div>
                <div className="fontStyle-block-wrap">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            checked={state.faceNarration.headMovement}
                            id="flexCheckDefault"
                            name='headMovement'
                            onChange={handleChange}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                            Include head movements
                        </label>
                    </div>
                </div>

                <div className="fontStyle-block-wrap">
                    <div className="row">
                        <div className='col-sm-6'>
                            <div className=" facelogo_position  ">
                                <h2 className='pb-3 mt-2'>Face Position</h2>
                                <div className="d-flex justify-content-center align-content-center flex-wrap">
                                    <div className={`position-single ${state.faceNarration.media.pos === "top-left" ? "active" : ""}`} onClick={() => handlePositons("top-left")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "top-center" ? "active" : ""}`} onClick={() => handlePositons("top-center")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "top-right" ? "active" : ""}`} onClick={() => handlePositons("top-right")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "center-left" ? "active" : ""}`} onClick={() => handlePositons("center-left")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "center" ? "active" : ""}`} onClick={() => handlePositons("center")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "center-right" ? "active" : ""}`} onClick={() => handlePositons("center-right")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "bottom-left" ? "active" : ""}`} onClick={() => handlePositons("bottom-left")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "bottom-center" ? "active" : ""}`} onClick={() => handlePositons("bottom-center")}></div>
                                    <div className={`position-single ${state.faceNarration.media.pos === "bottom-right" ? "active" : ""}`} onClick={() => handlePositons("bottom-right")}></div>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-6'>
                            <div className=" facelogo_shape">
                                <h2 className='pb-3 mt-2'>Face Shape</h2>
                                <div className="d-flex justify-content-between align-content-center flex-wrap mt-3 px-4">
                                    <div className={`position-circle ${state.faceNarration.media.shape === "circle" ? "active" : ""}`} onClick={() => handleShape("circle")} style={{ cursor: "pointer", borderRadius: "100%" }}></div>
                                    <div className={`position-circle ${state.faceNarration.media.shape === "square" ? "active" : ""}`} onClick={() => handleShape("square")} style={{ cursor: "pointer" }}></div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

                <div className="inpx-wrap pt-3">
                    <div className='row align-items-center'>
                        <div className='col-lg-3'><label htmlFor="">Coverage</label></div>
                        <div className='col-lg-9'>
                            <select
                                className="campaign-name-inp-box"
                                name="coverage"
                                value={state.faceNarration.coverage}
                                onChange={handleChange}
                            >
                                <option value={""}>Select Coverage</option>
                                <option value={10}>10%</option>
                                <option value={25}>25%</option>
                                <option value={50}>50%</option>
                            </select>
                        </div>
                    </div>
                    {state.faceNarration.coverage !== "" ?
                        <div className={`alert alert-${freqMessage.color} mt-3 text-center `} role="alert">
                            {freqMessage.message}
                        </div>
                        : null}
                </div>

            </div>
        </>
    )
}

export default CreateTalkingHead
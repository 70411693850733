import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMediaLayer, onFetchGifs, updateBackground, updateMediaLayer } from '../../../actions/contentAction';

import { MdCrop } from "react-icons/md";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { themeColor } from '../../../global/global';

const Gifs = ({ selectedSlideIndex, selectedLayerIndex, selectedSlide, type, selectedLayer }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.content.campaign)

    const [gifs, setGifs] = useState([])
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false)
    const [state, setState] = useState({
        key: "",
        checkImage: false,
    })

    const onFormSubmit = (e) => {
        e.preventDefault()
        fetchGifs()
    }
    const handleClick = () => {
        if (state.key !== "") {
            fetchGifs()
        }
    }

    const handleChange = (val) => {
        setGifs([])
        setError(false)
        setState({
            ...state,
            key: val,
            checkImage: false
        })
    }
    const fetchGifs = () => {
        setError(false)
        let data = {
            types: state.key
        }
        setLoader(true)
        dispatch(onFetchGifs(data, setGifs, setLoader, setError))
    }

    const selectGif = (curElem, index) => {
        let obj = JSON.parse(JSON.stringify(curElem.images).replace("480w_still", "thumbData")) //this is require for thumbnail 
        const data = {
            url: curElem.images.original.mp4,
            thumbnail: obj.thumbData.url
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "video"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "video", campaign.dimension))
                }
                setState({
                    ...state,
                    checkImage: index
                })
            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "video", campaign.dimension))
                setState({
                    ...state,
                    checkImage: index
                })
            }

        } else {
            return new Promise((resolve, reject) => {
                let au = document.createElement('video');
                au.src = data.url
                au.addEventListener('loadedmetadata', function () {
                    setState({
                        ...state,
                        checkImage: index
                    })
                    data.bgDuration = Math.round(au.duration)
                    dispatch(updateBackground(data, selectedSlideIndex, "video"))
                    resolve(au.duration)
                }, false);
            })
        }
    }

    return (
        <div className="innertab">
            <form className="tabSearch"
                onSubmit={(e) => onFormSubmit(e)}
            >
                <input
                    type="text"
                    placeholder="Search"
                    onInput={(e) => handleChange(e.target.value)}
                    required
                />
                <span
                    className="inp-icon"
                    onClick={() => handleClick()}
                ><i class="fa fa-search" aria-hidden="true"></i></span>
            </form>
            <div className="innertab-scroll" id="scrollableImage" >
                <div className="row">
                    <div className="mediaList">
                        <ul>

                            {gifs.length > 0 ?
                                gifs.map((curElem, index) => {
                                    return (

                                        <li className='myImage-section cursor-pointer'
                                            key={index}
                                        >
                                            <div className="mediaList-single myImage-respo m-1">
                                                <img className="mediaImg"
                                                    src={curElem.images.original.url}
                                                    alt={`${state.key + " " + index}`}
                                                    onClick={() => selectGif(curElem, index)}
                                                />

                                                {
                                                    type === "media" ?
                                                        selectedLayer ?
                                                            selectedLayer.type === "video" ?
                                                                curElem.images.original.mp4 === selectedLayer.src ?
                                                                    <div className="slide-active">
                                                                        <div className='slide-check'><IoCheckmarkDoneOutline /></div>
                                                                    </div>
                                                                    : ''
                                                                : ""
                                                            : ""
                                                        :
                                                        state.checkImage === index ?
                                                            <div className="slide-active">
                                                                <div className='slide-check'><IoCheckmarkDoneOutline /></div>
                                                            </div>
                                                            : ''
                                                }

                                            </div>
                                        </li>


                                    )
                                })
                                : ''
                            }


                            {
                                loader ?
                                    <div className="col-md-12">
                                        <h4 className='text-center'>
                                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor }} />
                                        </h4>
                                    </div>
                                    : ''
                            }
                            {
                                error ?
                                    <div className="col-md-12">
                                        <h6 className='text-center'>{error}</h6>
                                    </div>
                                    : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Gifs;

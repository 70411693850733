import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import img1 from "../../images/campaign-img.png";
import { BsArrowLeft } from "react-icons/bs"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onPlatform, onSubmitVideoSet, onfetchCampaign } from "../../actions/contentAction";
import SummerNote from "./SummerNote";
import Plateform from "./Plateform";
import queryString from "query-string"
import CreateTalkingHead from "./CreateTalkingHead";
import TalkingInstructionModel from "./TalkingInstructionModel";


import { FiUpload } from "react-icons/fi";
import { IoIosMic } from "react-icons/io";
import { useImmer } from "use-immer";
import UploadVoiceClone from "./UploadVoiceClone";
import { fetchData } from "../../actions/CommonActions";
import { setAlert } from "../../actions/alert";


const CreateVideoSet = ({ location }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = queryString.parse(location.search)

    const createdCamp = useSelector(state => state.content.createdCamp)

    const [platformData, setPlatformData] = useState(false)

    const [uploadVoice, setUploadVoice] = useState([]);
    const [recordedVoice, setRecordedVoice] = useState([]);
    const [voices, setVoices] = useState([])
    const [loader, setLoader] = useState({
        submit: false,
        fetch: true
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [state, setState] = useImmer({
        campaignsId: id,
        name: "",
        keyword: "",
        videoLimit: 1,
        platformId: "",
        dimension: "",
        promo: {
            enable: false,
            data: "",
            position: "top-left"
        },
        faceNarration: {
            enable: false,
            media: {
                type: "image",
                url: "",
                pos: "bottom-right",
                height: 125,
                width: 125,
                shape: "circle"
            },
            coverage: 10,
            headMovement: false
        },
        voiceClone: {
            enable: false,
            url: ""
        },
    })

    const handleProceed = (e) => {
        e.preventDefault()
        if (state.voiceClone.enable && !state.voiceClone.url) {
            dispatch(setAlert("Please select a sample voice by clicking on the tick mark to continue!", "danger"))
        } else {
            setLoader({
                ...loader,
                submit: true
            })
            dispatch(onSubmitVideoSet(state, loader, setLoader, history))
        }

    }
    const fetchPlateform = () => {
        dispatch(onPlatform(setPlatformData))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        console.log("hiiiii",value)
        // if(value>100){
        //     // alert("hiiii")
        //     dispatch(setAlert("hiiiiiii", "danger"));
        // }
        if (name === "platform") {
            let obj = JSON.parse(value)
            setState({
                ...state,
                dimension: obj.dimension,
                platformId: obj.id
            })
        } else {
            setState({
                ...state,
                [name]: value
            })
        }

    }

    const handlePromo = (value) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                position: value
            }
        })
    }

    const handleChangePromo = (e) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                enable: e.target.checked
            }
        })
    }

    const handleChangeFace = (e) => {
        setState({
            ...state,
            faceNarration: {
                ...state.faceNarration,
                enable: e.target.checked
            }
        })
    }

    const handleChangeVoice = (e) => {
        setState((draft) => {
            draft.voiceClone.enable = e.target.checked
        })
    }

    const fetchCampaign = () => {
        let data = {
            campaignId: id
        }
        dispatch(onfetchCampaign(data, loader, setLoader))
    }

    const fetchUploadVoice = () => {
        let data = { type: "voiceover" }
        dispatch(fetchData("fetch-user-uploaded-file", data, setUploadVoice, loader, setLoader))
        data.type = "recording"
        dispatch(fetchData("fetch-user-uploaded-file", data, setRecordedVoice, loader, setLoader))
    }

    useEffect(() => {
        let arr = [...uploadVoice, ...recordedVoice]
        if (arr.length > 0) {
            setVoices(
                arr.filter((curElem) => {
                    return {
                        id: curElem.id,
                        name: curElem.name,
                        url: curElem.url,
                        type: curElem.type ? curElem.type : "Voiceover"
                    }
                })
            )
        } else {
            setVoices(arr)
        }
    }, [uploadVoice, recordedVoice])

    useEffect(() => {
        if (id) {
            fetchCampaign()
        }
    }, [id])

    useEffect(() => {
        fetchPlateform()
        fetchUploadVoice()
    }, [])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Create Video Set" />
                <Navbar />
                <section className="siteWrap">
                    <div className="contWrap">
                        <div className="container">
                            <div className="contWrap-title">
                                <Link to={`/video-set?id=${id}`} style={{ color: "white", textTransform: "capitalize" }}>
                                    <h2><BsArrowLeft /> {createdCamp.campaignName ? createdCamp.campaignName : ""}</h2>
                                </Link>
                            </div>
                            <div className="campaign-name">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="campaign-name-left">
                                            <div><img src={img1} alt="" /></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">

                                        <form className="campaign-name-mid" onSubmit={handleProceed}>
                                            <h5 className="pt-0 pb-2">Create New Video Set</h5>
                                            <div className="campaign-name-inp">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-3">
                                                        <label><span tooltip="Provide a unique, descriptive name to easily identify your videos." tooltip-position="right">Video Set</span></label>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <input
                                                            className="campaign-name-inp-box"
                                                            type="text"
                                                            name="name"
                                                            placeholder="Instagram Videos"
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="campaign-name-inp">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-3">
                                                        <label><span tooltip="Provide keywords to help AI engines understand the content of your video." tooltip-position="right">Keyword</span></label>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <input
                                                            className="campaign-name-inp-box"
                                                            type="text"
                                                            name="keyword"
                                                            placeholder="Weight Loss"
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="campaign-name-inp">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-3">
                                                        <label><span tooltip="Input the quantity of videos you intend to produce." tooltip-position="right"># of video</span></label>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <input
                                                            className="campaign-name-inp-box"
                                                            type="number"
                                                            name="videoLimit"
                                                            placeholder="max video 100"
                                                            min={1}
                                                            max={100}
                                                            onWheel={(event) => event.currentTarget.blur()}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="campaign-name-inp">
                                                <div className="row align-items-center">
                                                    <div className="col-lg-3">
                                                        <label><span tooltip="Choose the platform where you wish to publish your videos" tooltip-position="right">Platform</span></label>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <select
                                                            name="platform"
                                                            className="campaign-name-inp-box"
                                                            onChange={handleChange}
                                                            required
                                                            style={{ textTransform: "capitalize" }}
                                                        >
                                                            <option value="">Select Platform</option>
                                                            {platformData ?
                                                                <>
                                                                    <Plateform
                                                                        data={platformData.Facebook}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.Instagram}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.LinkedIn}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.Pinterest}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.Snapchat}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.TikTok}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.Twitter}
                                                                    />
                                                                    <Plateform
                                                                        data={platformData.YouTube}
                                                                    />
                                                                </>

                                                                : ""}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="campaign-name-inp">

                                                <div className="opt-expand mt-2">
                                                    <label><span tooltip="Craft a compelling call-to-action (CTA) message to display in-video" tooltip-position="right">CTA</span></label>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={state.promo.enable}
                                                            onChange={handleChangePromo}
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>


                                                {state.promo.enable ?
                                                    <>
                                                        <div className="mt-3 ck-class">
                                                            <SummerNote
                                                                setState={setState}
                                                                state={state}
                                                            />
                                                        </div>

                                                        <div className="row align-items-center mt-3">
                                                            <div className="col-sm-12">
                                                                <div className="row align-items-center">
                                                                    <div className="col-sm-3">
                                                                        <label><span tooltip="Position" tooltip-position="right">Position</span></label>
                                                                    </div>
                                                                    <div className="col-sm-9">

                                                                        <div className="visual-position">
                                                                            <div className="visual-position-top">
                                                                                <div className={`position-single ${state.promo.position === "top-left" ? "active" : ""}`} onClick={() => handlePromo("top-left")}></div>
                                                                                <div className={`position-single ${state.promo.position === "top-center" ? "active" : ""}`} onClick={() => handlePromo("top-center")}></div>
                                                                                <div className={`position-single ${state.promo.position === "top-right" ? "active" : ""}`} onClick={() => handlePromo("top-right")}></div>
                                                                            </div>

                                                                            <div className="visual-position-top">
                                                                                <div className={`position-single ${state.promo.position === "center-left" ? "active" : ""}`} onClick={() => handlePromo("center-left")}></div>
                                                                                <div className={`position-single ${state.promo.position === "center" ? "active" : ""}`} onClick={() => handlePromo("center")}></div>
                                                                                <div className={`position-single ${state.promo.position === "center-right" ? "active" : ""}`} onClick={() => handlePromo("center-right")}></div>
                                                                            </div>

                                                                            <div className="visual-position-top">
                                                                                <div className={`position-single ${state.promo.position === "bottom-left" ? "active" : ""}`} onClick={() => handlePromo("bottom-left")}></div>
                                                                                <div className={`position-single ${state.promo.position === "bottom-center" ? "active" : ""}`} onClick={() => handlePromo("bottom-center")}></div>
                                                                                <div className={`position-single ${state.promo.position === "bottom-right" ? "active" : ""}`} onClick={() => handlePromo("bottom-right")}></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </> : ""}
                                            </div>

                                            <div className="campaign-name-inp">

                                                <div className="opt-expand mt-2">
                                                    <label><span tooltip="Animated image lip-syncs, enhancing messages with realistic expressions." tooltip-position="right">Talking Head</span></label>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={state.faceNarration.enable}
                                                            onChange={handleChangeFace}
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>

                                                {state.faceNarration.enable ?
                                                    <>
                                                        <CreateTalkingHead
                                                            state={state}
                                                            setState={setState}
                                                            handleShow={handleShow}
                                                        />
                                                    </>


                                                    : null}
                                            </div>


                                            <div className="campaign-name-inp">
                                                <div className="opt-expand mt-2">
                                                    <label><span tooltip="Replicate voices for personalized, authentic, and consistent audio content." tooltip-position="right">Voice Clone</span></label>
                                                    <label class="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={state.voiceClone.enable}
                                                            onChange={handleChangeVoice}
                                                        />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                {state.voiceClone.enable ?
                                                    <UploadVoiceClone
                                                        state={state}
                                                        setState={setState}
                                                        fetchUploadVoice={fetchUploadVoice}
                                                        uploadVoice={voices}

                                                    />
                                                    : null}
                                            </div>


                                            <div className="campaign-name-inp">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-12">
                                                        <div>
                                                            <button className="demoLink" type="submit">{loader.submit ? <>Submitting <i className="fa fa-spinner fa-spin mr-2" /> </> : "Submit"}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="campaign-name-left">
                                            <div><img src={img1} alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TalkingInstructionModel
                        show={show}
                        handleClose={handleClose}
                    />
                </section>

                <Footer />
            </>
    )
}

export default CreateVideoSet;
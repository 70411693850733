import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontStyle from './FontStyle';
import ColorPicker from './ColorPicker';
import { addInnerStyle, applyToAllSlide, removeInnerStyle, textLayerAdd, textLayerUpdate } from '../../../actions/contentAction';
import ContentEditable from 'react-contenteditable';
import InlineColorPicker from './InlineColorPicker';
import { BsQuestionCircle } from "react-icons/bs"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import sanitizeHtml from 'sanitize-html';
import { disableDelete, enableDelete } from '../../../actions/EditorActions';
import Gradient from './Gradient';
import { onFetchFont } from '../../../actions/CommonActions';
import { setAlert } from '../../../actions/alert';


const Text = () => {

  const dispatch = useDispatch()
  const layerTextRef = useRef(null)
  const campaign = useSelector(state => state.content.campaign)
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const textIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
  const selectedText = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

  const [layerText, setLayerText] = useState("")
  const [slideAll, setSlideAll] = useState(false);
  const [fonts, setFonts] = useState([])
  const [isGradient, setIsGradient] = useState(false)
  const [restriction, setRestriction] = useState(true);
  const [dataHigh, setDataHigh] = useState({
    text: "",
    color: "",
    background: ""
  })

  const [styleState, setStyleState] = useState({
    color: "#000000",
    fontFamily: "",
    background: "",
    fontSize: "36",
    textAlign: "center",
    fontWeight: false,
    fontStyle: false,
    textTransform: "lowercase",
    textDecoration: "none",
    lineHeight: 1,
    opacity: 1,
  })

  const addTextLayer = () => {
    dispatch(textLayerAdd(selectedSlideIndex))
  }

  const onLayerTextChange = (text) => {
    if (selectedText.type === "text") {
      const clean = sanitizeHtml(text, {
        allowedTags: []
      });
      setLayerText(clean)
      dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
    }
  }

  const fetchFonts = () => {
    dispatch(onFetchFont(setFonts, false))
  }

  const applyChanges = () => {
    if (slideAll === false) {
      setSlideAll(true);
      dispatch(setAlert("Applied successfully", "success"));
      dispatch(applyToAllSlide(styleState, selectedText.innerCss));
    }
  }

  const handleChecked = (e) => {
    if (selectedText.type === "text") {
      setSlideAll(false);
      if (e.target.checked) {
        dispatch(addInnerStyle(selectedSlideIndex, textIndex, styleState.background))
      } else {
        dispatch(removeInnerStyle(selectedSlideIndex, textIndex))
      }
    }
  }


  const handleClick = (event) => {
    let text = event.currentTarget.innerText;
    setRestriction(false)
    if (selectedText.type === "text") {
      const clean = sanitizeHtml(text, {
        allowedTags: []
      });
      setLayerText(clean)
      dispatch(textLayerUpdate(clean, selectedSlideIndex, textIndex))
    }
  }

  const handleBlur = () => {
    dispatch(enableDelete())
    // setRestriction(true)
  }


  const handleHighlight = (e) => {
    const selObj = window.getSelection().toString();

    if (selObj !== "") {
      setDataHigh({
        ...dataHigh,
        text: selObj
      })
    }
  }

  const handleOutsideText = () => {
    dispatch(disableDelete())

  }

  useEffect(() => {
    if (selectedText) {
      setStyleState({
        ...selectedText.style
      })
      if (selectedText.style.background.includes("linear-gradient")) {
        setIsGradient(true)
      } else {
        setIsGradient(false)
      }
      setLayerText(selectedText.src)
    }
  }, [selectedText])

  useEffect(() => {
    fetchFonts()
  }, [])



  return (
    <div className="tabInner">
      <div className='tabTitle'>Text</div>
      <div className="textarea-block-wrap mt-0 mb-4">
        <div className="textarea-block-head d-flex justify-content-between align-items-center">
          <h2>Text</h2>

          <button
            className='textarea-btn alt'
            onClick={addTextLayer}
          >Add New Text Layer</button>
        </div>
        {selectedText ?
          selectedText.type === "text" ?
            <div className="textarea-wrapper pt-3 ">
              <div className="mb-3">
                <ContentEditable
                  className="form-control"
                  html={layerText}
                  disabled={false}
                  onBlur={handleBlur}
                  onClick={(e) => handleClick(e)}
                  onMouseUp={() => handleHighlight()}
                  onChange={(e) => onLayerTextChange(e.target.value)}
                  style={{ height: "100px", overflowX: "hidden", overflowY: "auto" }}
                />
              </div>
            </div> : "" : ""}
      </div>
      {
        selectedText ?
          selectedText.type === "text" || selectedText.type === "shape" ?
            <div className="txt-format">
              {selectedText.type === "text" ?
                <>
                  <FontStyle
                    styleState={styleState}
                    selectedText={selectedText}
                    selectedSlide={selectedSlide}
                    slideIndex={selectedSlideIndex}
                    setSlideAll={setSlideAll}
                    fonts={fonts}
                  />
                  <div className="fontStyle-block-wrap">
                    <h2>Color</h2>
                    <ColorPicker
                      colorCode={styleState.color}
                      setStyleState={setStyleState}
                      selectedSlide={selectedSlide}
                      slideIndex={selectedSlideIndex}
                      type="textColor"
                      setSlideAll={setSlideAll}
                    />

                  </div>
                </>
                : ""}

              {selectedText.type === "text" || selectedText.type === "shape" ?
                isGradient ? null :
                  <div className="fontStyle-block-wrap">
                    <h2>Background</h2>
                    <ColorPicker
                      colorCode={selectedText.innerCss ? selectedText.innerCss.background : styleState.background}
                      setStyleState={setStyleState}
                      selectedSlide={selectedSlide}
                      slideIndex={selectedSlideIndex}
                      type="bgColor"
                      setSlideAll={setSlideAll}
                    />
                  </div>

                : ""}
              {selectedText.type === "text" ?
                <>
                  <div className="fontStyle-block-wrap">
                    <div className='d-flex align-items-center'>
                      <label className='cursor-pointer' style={{ display: "contents" }}>
                        <input
                          type="checkbox"
                          id='addTtsAll'
                          checked={selectedText.innerCss && selectedText.type === "text"}
                          onChange={(e) => handleChecked(e)}
                          style={{ width: '28px', height: "19px" }}
                        />
                        <span className='align-self-center ms-2' >Add Space Between Text</span>
                      </label>
                    </div>
                  </div>
                  <div className="fontStyle-block-wrap mb-3">
                    <h2>Highlight Color
                      <span className='mx-1' >

                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id="tooltip-top" >
                              Please select words first to highlight it.
                            </Tooltip>
                          }
                        >
                          <p style={{ display: "inline-block", position: "relative", top: "-1px" }}> <BsQuestionCircle color='#b571e9' className='mb-0 ms-1' size={17} cursor="help" /></p>


                        </OverlayTrigger>


                      </span>
                    </h2>
                    <InlineColorPicker
                      dataHigh={dataHigh}
                      slideIndex={selectedSlideIndex}
                      layerIndex={textIndex}
                      selectedText={selectedText}
                      type="color"
                    />
                  </div>

                  <div className="fontStyle-block-wrap mb-3">
                    <h2>Highlight Background Color
                      <span className='mx-1' >

                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id="tooltip-top" >
                              Please select words first to highlight it.
                            </Tooltip>
                          }
                        >
                          <p style={{ display: "inline-block", position: "relative", top: "-1px" }}> <BsQuestionCircle color='#b571e9' className='mb-0 ms-1' size={17} cursor="help" /></p>
                        </OverlayTrigger>
                      </span>

                    </h2>
                    <InlineColorPicker
                      dataHigh={dataHigh}
                      slideIndex={selectedSlideIndex}
                      layerIndex={textIndex}
                      selectedText={selectedText}
                      type="bgcolor"

                    />
                  </div>
                  <div className="fontStyle-block-wrap">
                    <div className="itemDisp">
                      <div className="itemDisp-left">
                        Apply to all slides
                      </div>
                      <div className="itemDisp-right">
                        <div className="switch-single">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={slideAll}
                              disabled={slideAll}
                              onChange={() => applyChanges()}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                </> : ""}
            </div>
            : "" : ""
      }

      {isGradient ?
        <div className="font-block mt-4">
          <h5 className='text-capitalize'>Gradient</h5>
          <div className="font-color pt-2">
            <div className="row sm">
              <Gradient
                type="left"
                styleState={styleState}
                selectedSlideIndex={selectedSlideIndex}
                textIndex={textIndex}
              />
              <Gradient
                type="right"
                styleState={styleState}
                selectedSlideIndex={selectedSlideIndex}
                textIndex={textIndex}
              />
            </div>
          </div>
        </div> : ""}

    </div >
  );
};

export default Text;

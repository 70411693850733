import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsArrowLeft } from "react-icons/bs"
import { onFetchVideos, onfetchCampaign, removeVideo } from "../../actions/contentAction";
import { useDispatch, useSelector } from "react-redux";
import VideoListCard from "./VideoListCard";
import queryString from "query-string"


const VideoList = ({ location }) => {

    const dispatch = useDispatch()
    const { id, vsid } = queryString.parse(location.search)
    const createdCamp = useSelector(state => state.content.createdCamp)
    const [videoData, setVideoData] = useState(false)
    const [loader, setLoader] = useState(true)
    const [fetchLoader, setFetchLoader] = useState({
        fetch: false
    })
    const [state, setState] = useState({
        id: -1,
        button: "Delete",
        show: false,
        name: ""
    })

    const handleDelete = (data) => {
        setState({
            ...state,
            id: data.id,
            show: true,
            name: data.name
        })
    }

    const onConfirm = () => {
        setState({
            ...state,
            button: "Deleting..."
        })
        dispatch(removeVideo(state, setState, fetchVideos))
    }

    const fetchVideos = () => {
        let data = {
            videoSetId: vsid,
        }
        dispatch(onFetchVideos(data, setVideoData, setLoader))
    }



    const fetchCampaign = () => {
        let data = {
            campaignId: id
        }
        setFetchLoader({
            ...fetchLoader,
            fetch: true
        })
        dispatch(onfetchCampaign(data, fetchLoader, setFetchLoader))
    }

    useEffect(() => {
        if (id) {
            fetchCampaign()
        }
    }, [id])


    useEffect(() => {
        if (vsid) {
            // console.log("vsid call")
            fetchVideos()
        }
    }, [vsid])

    return (
        loader ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Video List" />
                <Navbar />
                <section className="siteWrap">
                    <div className="contWrap">
                        <div className="container">
                            <div className="contWrap-title">
                                <Link to={`/video-set?id=${id}`} style={{ color: "white", textTransform: "capitalize" }}>
                                    <h2><BsArrowLeft />{createdCamp.campaignName}  <span>|</span> {videoData ? videoData.platform.name : ""} <span>|</span> {videoData.keyword} </h2>
                                </Link>
                            </div>
                            <div>
                                <div className="row">
                                    {videoData ?
                                        videoData.videos.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <VideoListCard
                                                        handleDelete={handleDelete}
                                                        fetchVideos={fetchVideos}
                                                        curElem={curElem}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        : ""
                                    }

                                    {/* <div className="col-xl-3 col-md-6 col-sm-12">
                                    <div className="videoProject-single alt">
                                        <div className="videoProject-single-top size-9-16">
                                            <div className="video-card-wrapper-1 dashboard-card-bg  p-0">
                                                <div className="video-card-content"></div>
                                            </div>
                                        </div>
                                        <div className="videoProject-single-bottom">
                                            <div className="videoProject-single-edIcon cursor-pointer"><i class="bi bi-pencil-square"></i></div>
                                            <div className="videoProject-single-edName text-light">
                                               <span>Weight Loss_1</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                    {videoData ?
                                        videoData.videos.length > 0 ? ""
                                            :
                                            <div className="col-md-12 text-center mt-5">
                                                <h6>
                                                    "No Videos Created Yet!"
                                                </h6>
                                            </div>

                                        :
                                        <div className="col-md-12 text-center mt-5">
                                            <h6>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#3DAEC7", fontSize: "30px" }} /> :
                                                    ""}
                                            </h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={state.button}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    title="Are you sure?"
                    onConfirm={(e) => onConfirm(e)}
                    onCancel={(e) => setState({
                        ...state,
                        show: false
                    })}
                    focusCancelBtn
                    show={state.show}
                >
                    <div style={{ color: "gray", fontWeight: "bold" }}>
                        Deleting the video "{state.name}" will delete all slides of it.
                    </div>
                </SweetAlert>
                <Footer />
            </>
    )
}

export default VideoList;
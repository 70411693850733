import React, { useState } from "react";
import instaIcon from "../../images/instagram.png"

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchSocialAccounts, saveFacebook } from "../../actions/socialAction";
import SweetAlert from "react-bootstrap-sweetalert";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import { InstagramLogin } from '@amraneze/react-instagram-login';

const ConnectInsta = (props) => {
console.log(props.data)
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);

    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user.id
    })

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const deleteIntegration = (id) => {
        setIntData({ ...intData, id: id });
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton('Deleting...');
        deleteSocialAccounts();
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-social-network`,
            data: intData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.token));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const [groups, setGroups] = useState([]);

    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = async () => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        await dispatch(saveFacebook(data));
        setDetails({ ...details, openPopup: false });
        setLoader(false);
    };

    const responseInstagram = async (response) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );

            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /** Fetch FB Groups */
        try {
            const res = await axios.get(
                " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="connection-box mt-3" id="insta">

                <div className="connection-box-main">
                    <div className={`connection-img ${props.data.length > 0 ? '' : 'disable-img'}`}>
                        <img src={instaIcon} width="56px" alt="insta" />
                    </div>

                    <div className="connection-txt">

                        {
                            props.data.length > 0 ?
                                props.data.map((item, index) => {
                                    return (
                                        <div>
                                            <div key={index} className="facebook-connect-account text-white d-flex justify-content-between  align-items-center">
                                                <div className="facebook-content">
                                                    <h6>{props.name}</h6>
                                                    <p className="m-0">{item?.username} - Added on {item.created}</p>
                                                </div>
                                                <div className="facebook-delete">
                                                    <button type="button" onClick={(e) => deleteIntegration(item.id)} className="btn-change7">Disconnect</button>
                                                </div>
                                            </div>
                                            <div className="facebook-connect-account d-flex justify-content-center  mt-4">
                                                <InstagramLogin
                                                    clientId="780210132546000"
                                                    buttonText="Connect New Account"
                                                    cssClass="btn-change7"
                                                    onSuccess={responseInstagram}
                                                    onFailure={responseInstagram}
                                                    scope="instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,ads_management,business_management"
                                                />
                                                {/* <FacebookLogin
                                                    appId="780210132546000" //APP ID NOT CREATED YET
                                                    fields="name,email"
                                                    scope="pages_manage_metadata,ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement"
                                                    // scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                                    callback={(e) => responseFacebook(e)}
                                                    render={renderProps => (
                                                        <button onClick={renderProps.onClick} className="btn-change7">Connect New Account</button>
                                                    )}
                                                /> */}
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="noConnect">
                                    <div className="youbtube-connection text-white d-flex align-items-center  justify-content-between">
                                        <div className="youtube-content">
                                            <h6>{props.name}</h6>
                                            <p className="m-0">Not Connected</p>
                                        </div>
                                    </div>
                                    <div className="facebook-connect-account">
                                        <InstagramLogin
                                            clientId="780210132546000"
                                            buttonText="Connect"
                                            cssClass="btn-change7"
                                            onSuccess={responseInstagram}
                                            onFailure={responseInstagram}
                                            scope="instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,ads_management,business_management"
                                        />
                                        {/* <FacebookLogin
                                            appId="780210132546000" //APP ID NOT CREATED YET
                                            fields="name,email"
                                            scope="pages_manage_metadata,ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                            // scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                                            callback={(e) => responseFacebook(e)}
                                            render={renderProps => (
                                                <button onClick={renderProps.onClick} className="btn-change7">Connect</button>
                                            )}
                                        /> */}
                                    </div>
                                </div>
                        }



                    </div>
                </div>
            </div>

            <div className={`modal ${details.openPopup ? 'show-modal' : ''} `}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content" style={{ maxWidth: "500px", margin: "auto" }}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Connect {props.name}
                            </h5>
                            <button onClick={(e) => closeConnectModel()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" style={{ backgroundColor: "transparent" }}>
                                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChange}
                                    value={dropValue}>
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </form>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <div className="row">
                                <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                    <div className="add-account-save-btn">
                                        <button type="submit" className="btn-change7" onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}>
                                            {loader ? <>Connecting <i className="fa fa-spinner fa-spin mr-2" /> </> : "Connect"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete facebook account
            </SweetAlert>
        </>
    )
}

export default ConnectInsta;
import React, { useEffect, useState } from 'react'
import { AudioVisualizer } from 'react-audio-visualize'
import { FiMusic } from 'react-icons/fi'

const BackgroundAudio = ({ campaign, basic }) => {
    const [aud, setAud] = useState(false)
    let dummyData = [
        "https://s3-us-west-2.amazonaws.com/mediaapidata/musics/full_length_tracks/Anticipation_3m.mp3",
        "https://s3-us-west-2.amazonaws.com/mediaapidata/musics/full_length_tracks/Walking_Away_3m.mp3",
        "https://s3-us-west-2.amazonaws.com/mediaapidata/musics/full_length_tracks/Creepy.mp3",
        "https://s3-us-west-2.amazonaws.com/mediaapidata/musics/full_length_tracks/Space_Dance_3m.mp3"
    ]

    const generateWaves = (src) => {
        fetch(src)
            .then(response => response.blob())
            .then((d) => {
                setAud(d)
            })
            .catch((e) => {
                return false
            })
    }

    useEffect(() => {
        if (campaign.bgAudio) {
            if (campaign.bgAudio.enable) {
                let au = new Audio(campaign.bgAudio.source)
                fetch(au.src)
                    .then(response => response.blob())
                    .then((d) => {
                        setAud(d)
                    })
                    .catch((e) => {
                        console.log(e)
                    })

                // const val = generateWaves(au.src)
                // if (!val) {
                //     let randVal = new Audio(dummyData[Math.floor(Math.random() * 4)])
                //     setAud(generateWaves(randVal.src))
                // }
            }
        }
    }, [campaign.bgAudio, basic])

    return (
        campaign.bgAudio.enable ?
            <div className="audioSlide-single" style={{ width: "100%" }}>
                <div className="audio-meta">
                    <div className="audio-meta-left">
                        <div className="audio-meta-icon"><FiMusic /></div>
                        <span >{campaign.bgAudio.source.slice(0, 50)}...</span>
                    </div>
                    <div className="audio-meta-right">{campaign.bgAudio.duration}</div>
                </div>
                <div
                // className="audio-bg"
                >
                    <AudioVisualizer
                        // ref={visualizerRef}
                        blob={aud}
                        width={(+basic.totalDuration * 100) - 20}
                        height={50}
                        barWidth={1}
                        gap={0}
                        barColor={'#2f997c'}
                    />

                </div>
            </div> : null
    )
}

export default BackgroundAudio
import './App.css';
import './responsive.css'
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoute } from "./components/PrivateRoute";
import { loadUser } from "./actions/authAction";
import { removeAlert } from './actions/alert';

import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import HelpSupport from "./components/support/HelpSupport";
import Training from "./components/support/Training";
import TrainingArticles from "./components/support/TrainingArticles";
import MasterLogin from "./components/MasterLogin";
import Integration from "./components/integration/Integration";
import Reseller from "./components/user/Reseller";
import Editor from './components/editor/Editor';
import Content from './components/project/Content';
import VideoSet from './components/createContent/VideoSet';
import CreateVideoSet from './components/createContent/CreateVideoSet';
import VideoList from './components/createContent/VideoList';
import "animate.css"
import Alert from './components/Alert';
import ContentSet from './components/createContent/ContentSet';
import Settings from './components/outreach/Settings';
import ReelMerge from './components/project/reelMerge/ReelMerge';
import { onFetchFont } from './actions/CommonActions';

function App() {

    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert)
    const auth = useSelector(state => state.auth)
    const [memberShip, setMemberShip] = useState([])


    const fetchFonts = () => {
        dispatch(onFetchFont(false, true))
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        dispatch(loadUser())
        fetchFonts()

    }, [])

    useEffect(() => {
        if (alert !== undefined) {
            if (alert.message !== "") {
                setTimeout(() => {
                    dispatch(removeAlert())
                }, 5000);
            }
        }
    }, [alert.message])

    return (
        <div className="App">
            <Alert />

            <Router>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={ForgetPassword} />
                    <Route exact path="/reset-password" component={ResetPassword} />
                    <Route exact path="/master-login" component={MasterLogin} />


                    <PrivateRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute exact path="/campaigns" component={Content} />
                    <PrivateRoute exact path="/privacy" component={Privacy} />
                    <PrivateRoute exact path="/account-management" component={AccountManagement} />
                    <PrivateRoute exact path="/reseller" component={Reseller} />
                    <PrivateRoute exact path="/upgrades" component={Upgrades} />
                    <PrivateRoute exact path="/help-and-support" component={HelpSupport} />
                    <PrivateRoute exact path="/training" component={Training} />
                    <PrivateRoute exact path="/support-article" component={TrainingArticles} />
                    <PrivateRoute exact path="/integration" component={Integration} />
                    <PrivateRoute exact path="/editor" component={Editor} />
                    <PrivateRoute exact path="/video-set" component={VideoSet} />
                    <PrivateRoute exact path="/create-videoset" component={CreateVideoSet} />
                    <PrivateRoute exact path="/video-list" component={VideoList} />
                    <PrivateRoute exact path="/settings" component={Settings} />
                    <PrivateRoute exact path="/content-set" component={ContentSet} />
                    {memberShip.includes("professional") || memberShip.includes("professional-lite") || memberShip.includes("fastpass") || memberShip.includes("bundle") ?
                        <PrivateRoute exact path="/reel-merge" component={ReelMerge} /> : ''}
                    <Route path="*" component={Login} />

                </Switch>
            </Router>
        </div>
    );
}

export default App;

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import swal from "sweetalert"
import TranslateText from './TranslateText';
import { onFetchLang, onFetchVoice, onGenerateTts, onGenerateTtsAll, updateTtsText, updateTtsUrl } from '../../../actions/contentAction';
import { FaRegSave } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import Swal from 'sweetalert2';
import { themeColor } from '../../../global/global';
import { generationDisableTTS } from '../../../actions/EditorActions';

let ttsAudio = false;
const AiVoice = () => {


    const dispatch = useDispatch()
    const campaign = useSelector(state => state.content.campaign)
    const editor = useSelector(state => state.editor)

    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [addAllText, setAddAllText] = useState(false)
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [engine, setEngine] = useState("")
    const [ttsStatus, setTtsStatus] = useState(true);
    const [ttsText, setTtsText] = useState("")
    const [currentTts, setCurrentTts] = useState('');
    const [loader, setLoader] = useState(false);

    const [langData, setLangData] = useState({
        lang: '',
        voice: '',
        voiceId: "",
    })
    const [generateData, setGenerateData] = useState({
        url: "",
        duration: 0
    })

    const getLanguage = (e) => {
        const vId = pollyLanguages.data.find(({ code }) => code === e.target.value)
        if (vId) {
            fetchPollyVoices(vId.id);
        }
        setTtsStatus(true)
        setLangData({
            ...langData,
            lang: e.target.value
        })
    }

    const getVoices = (e) => {
        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        if (data) {
            setLangData({
                ...langData,
                voice: data.voice_id,
                voiceId: e.target.value
            })
            setEngine(data.type)
        } else {
            setLangData({
                ...langData,
                voice: "",
                voiceId: ""
            })
            setEngine("")
        }
        setTtsStatus(true)
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const generateTts = () => {
        if (ttsText !== "" && langData.lang !== "" && langData.voice !== "") {
            setLoader(true);
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                text: ttsText,
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            dispatch(onGenerateTts(data, generateData, setGenerateData, setLoader, setTtsStatus))

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please, add text, language & voice in TTS!',
                confirmButtonColor: themeColor
            })
        }
    }

    const handleAddAllText = (e) => {
        setAddAllText(e.target.checked)
        let textData = ""
        if (e.target.checked) {
            selectedSlide.layers.forEach((curElem) => {
                if (curElem.type === "text" && curElem.src !== "") {
                    textData += curElem.src.replaceAll("\n", "")
                    textData += "\n"
                }
            })
            dispatch(updateTtsText(textData, selectedSlideIndex))
        }
        else {
            dispatch(updateTtsText("", selectedSlideIndex))
            setTtsText("")
        }
    }



    const onEditTts = (e) => {
        setTtsText(e.target.value)
        setTtsStatus(true)
        dispatch(updateTtsText(e.target.value, selectedSlideIndex))
    }

    const applyTts = () => {
        if (+generateData.duration <= 30) {
            dispatch(updateTtsUrl(generateData, langData, selectedSlideIndex))
        } else {
            dispatch(setAlert("Max allowed duration is 30 seconds.", "danger"))
        }
    }

    const fetchPollyLanguages = () => {
        dispatch(onFetchLang(pollyLanguages, setPollyLanguages))
    }

    const fetchPollyVoices = (value, langCode) => {
        dispatch(onFetchVoice(value, pollyVoices, setPollyVoices, setLangData, langData, selectedSlide, langCode, setEngine))
    }

    const handleGenerateAll = (e) => {
        dispatch(generationDisableTTS())
        setTtsStatus(true)
        if (e.target.checked) {
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            dispatch(onGenerateTtsAll(campaign.slides, data))
        }
    }

    useEffect(() => {
        if (addAllText) {
            setAddAllText(false)
        }
        setTtsStatus(true)
        if (selectedSlideIndex !== -1) {
            let vId = pollyLanguages.data.find(({ code }) => code === selectedSlide.audio.meta.languageId)
            if (vId) {
                fetchPollyVoices(vId.id, selectedSlide.audio.meta.languageId)
            }
            setTtsText(selectedSlide.audio.meta.text)
        }
    }, [selectedSlideIndex, pollyLanguages])

    useEffect(() => {
        fetchPollyLanguages()
    }, [])


    return (


        <div className="innertab">
            <div className="alert alert-warning text-center">
                This voiceover would only be added to current slide
            </div>
            <div className="innertab-scroll alt">
                <div className="ai-voice-block">
                    <div className="voice-block-top">
                        <div className="row">
                            <div className="col-3">
                                <input className="form-control" type="text" name="slide"
                                    value={selectedSlideIndex + 1}
                                    readOnly />
                                <span className="optDesc text-light">SLIDE NO.</span>
                            </div>
                            <div className="col-5">
                                <select
                                    onChange={(e) => getLanguage(e)}
                                    value={langData.lang}
                                >
                                    <option value={""}>Select Language</option>
                                    {
                                        pollyLanguages.data.length > 0 ?
                                            pollyLanguages.data.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.name}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <span className="optDesc text-light">LANGUAGE</span>
                            </div>
                            <div className="col-4">
                                <select
                                    value={langData.voiceId}
                                    onChange={(e) => getVoices(e)}
                                    className="cursor-pointer text-hover-effect"
                                >
                                    <option value={""}>Select Voice</option>
                                    {
                                        pollyVoices.data.length > 0 ?
                                            pollyVoices.data.map((item, index) => {

                                                return (
                                                    <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <span className="optDesc text-light">VOICE</span>
                            </div>
                        </div>
                    </div>
                    <div className="voice-block-mid">
                        <textarea
                            placeholder="Add text here"
                            onChange={(e) => onEditTts(e)}
                            value={ttsText}
                        />
                        <div className="row">
                            <div className="voice-check-box pt-1">
                                {/* <label htmlFor='addTtsAll' className='d-flex' style={{ display: "contents" }} >
                                    <input
                                        type="checkbox"
                                        id='addTtsAll1'
                                        checked={addAllText}
                                        onChange={handleAddAllText}
                                        style={{ width: '28px', height: "19px" }}
                                    />
                                    <span className='align-self-center' style={{ fontSize: '13px' }}>Load all layers text from the slide</span>
                                </label> */}
                                {ttsStatus ?
                                    editor.voiceOver.isGenerating ?
                                        <div className='text-center'>
                                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} />
                                        </div>
                                        : ""

                                    :
                                    <label htmlFor='addTtsAll' className='d-flex'>
                                        <input
                                            type="checkbox"
                                            id='addTtsAll2'
                                            onChange={handleGenerateAll}
                                            style={{ width: '40px', height: "19px", }}
                                        />
                                        <span className='align-self-center ms-1' style={{ fontSize: '13px' }}>Use the same language & voice to generate voiceover for all the slides</span>
                                    </label>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="voice-block-bottom">
                        <div className="row">
                            <div className="col-7">
                                {ttsStatus ?
                                    <button
                                        disabled={loader}
                                        className="demoLink alt btn-block mb-sm-2 justify-content-center"
                                        onClick={() => generateTts()}
                                    >
                                        <span className="mr-1">
                                            <i className="fas fa-file-upload" />
                                        </span>
                                        {loader ?
                                            <>
                                                Generating
                                                <i className="fa fa-spinner fa-spin mx-1" />
                                            </> : "Generate"}

                                    </button>
                                    :
                                    <button className="demoLink btn-block "
                                        onClick={() => playTts(generateData.url)}
                                    >
                                        <span className="mr-1">
                                            <i className={`fas ${currentTts === generateData.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                        </span>
                                        Preview
                                    </button>
                                }
                            </div>
                            <div className="col-5" >
                                <button
                                    onClick={() => applyTts()}
                                    className={`${ttsStatus ? "demoLink btn-block" : ''} demoLink btn-block`}
                                    disabled={ttsStatus}
                                    style={{ background: ttsStatus ? "gray" : "" }}
                                >
                                    <FaRegSave /> Use
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
                {/* {memberShip.includes("professional") || memberShip.includes("professional-lite") || memberShip.includes("bundle") ?
                    <TranslateText
                        selectedSlideIndex={selectedSlideIndex}
                        selectedSlide={selectedSlide}
                        setDataToRevert={setDataToRevert}
                        memberShip={memberShip}
                    /> : ""} */}

            </div>
        </div>
    )
}

export default AiVoice
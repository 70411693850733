import React, { useEffect, useState } from 'react'
import UploadVoiceClone from '../../createContent/UploadVoiceClone'
import { useImmer } from 'use-immer'
import { fetchData, onUploadMedia } from '../../../actions/CommonActions'
import { useDispatch, useSelector } from 'react-redux'
import { FaCheck, FaPlay } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi'
import { themeColor } from '../../../global/global'
import Swal from 'sweetalert2'
import Recording from '../../createContent/Recording'
import AudioFiles from '../AudioFiles'
import { checkCloneVoice, onDeleteMedia, onGenerateClone, onGenerateCloneAll, onGenerateTtsAll, setTtsData, updateTtsText } from '../../../actions/contentAction'
import { MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { activeCloneLoader } from '../../../actions/EditorActions'

let music = false
const VoiceClone = ({ type }) => {
    const campaign = useSelector(state => state.content.campaign)
    const editor = useSelector(state => state.editor)
    const [activeUploadType, setActiveUploadType] = useState("voiceover")
    const [percent, setPercent] = useState(0)
    let allowedExt = ['audio/mpeg'];
    const [ttsText, setTtsText] = useState("")

    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedAudio, setSelectedAudio] = useState(false)
    const [play, setPlay] = useState(false)
    const [uploadVoice, setUploadVoice] = useState([]);
    const [recordedVoice, setRecordedVoice] = useState([]);
    const [voices, setVoices] = useState([])
    const [generatedVoice, setGeneratedVoice] = useState("")
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false,
        fetch: true,
        generate: false
    })

    const onUploadVoice = (e, type) => {
        let allowedSize = 5000000
        setActiveUploadType(type)
        if (e.target.files.length > 0 && allowedExt.includes(e.target.files[0].type)) {
            let upFile = e.target.files[0]
            const reader = new FileReader();
            reader.readAsArrayBuffer(e.target.files[0]);
            reader.onloadend = (e) => {
                const ctx = new AudioContext();
                const audioArrayBuffer = e.target.result;
                ctx.decodeAudioData(audioArrayBuffer, data => {
                    // this is the success callback
                    const duration = data.duration;
                    if (+duration <= 60) {
                        if (upFile.size < allowedSize) {
                            const formData = new FormData()
                            formData.append('upload_type', type)
                            formData.append('file', upFile)
                            dispatch(onUploadMedia(formData, fetchUploadVoice, loader, setLoader, setPercent, false,))

                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Max allowed size for Music File is 5MB!',
                                confirmButtonColor: themeColor
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Maximum allowed duration is 60 seconds to clone your voice!',
                            confirmButtonColor: themeColor
                        })
                    }
                }, error => {
                    // this is the error callback
                    console.error(error);
                });
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have Selected Invalid File Type!',
                confirmButtonColor: themeColor
            })
        }
    }

    const handleUse = (url) => {
        if (ttsText) {
            setSelectedAudio(url)
            let data = {
                text: ttsText,
                url
            }
            setLoader({
                ...loader,
                generate: true
            })
            dispatch(onGenerateClone(data, setGeneratedVoice, loader, setLoader))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please add text to generate clone voice!',
                confirmButtonColor: themeColor
            })
        }
    }


    const handleGenerateAll = () => {
        setGeneratedVoice("")
        dispatch(activeCloneLoader())
        dispatch(onGenerateCloneAll(campaign.slides, selectedAudio))
    }


    const removeMedia = (id, setButtonText, setShowDelete) => {
        let formData = new FormData();
        formData.append('id', id);
        dispatch(onDeleteMedia(formData, setShowDelete, setButtonText, fetchUploadVoice))
    }


    const handlePlay = () => {
        music = new Audio(generatedVoice.url)
        music.play()
            .then(r => {
                setPlay(true)
            }
            )
            .catch(err => console.log(err));
        music.onended = () => {
            setPlay(false)
        }
    }

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }




    const handleApply = () => {
        let audio = {
            src: generatedVoice.url,
            duration: generatedVoice.duration,
            originalSrc: selectedAudio,
            meta: {
                languageId: "",
                text: ttsText,
                translateText: "",
                voiceId: "",
            }
        }
        let au = document.createElement('audio');
        au.src = generatedVoice.url;
        au.addEventListener('loadedmetadata', function () {
            if (au.duration !== Infinity && au.duration !== 0) {
                audio.duration = Number((au.duration).toFixed(1));
                dispatch(setTtsData(audio, selectedSlideIndex, true))
            }
        }, false);
    }

    const onEditTts = (e) => {
        setTtsText(e.target.value)
        dispatch(updateTtsText(e.target.value, selectedSlideIndex))
    }



    const fetchUploadVoice = () => {
        let data = { type: "voiceover" }
        dispatch(fetchData("fetch-user-uploaded-file", data, setUploadVoice, loader, setLoader))
        data.type = "recording"
        dispatch(fetchData("fetch-user-uploaded-file", data, setRecordedVoice, loader, setLoader))
    }


    useEffect(() => {
        let arr = [...uploadVoice, ...recordedVoice]
        if (arr.length > 0) {
            setVoices(
                arr.filter((curElem) => {
                    return {
                        id: curElem.id,
                        name: curElem.name,
                        url: curElem.url,
                        type: curElem.type ? curElem.type : "Voiceover"
                    }
                })
            )
        } else {
            setVoices(arr)
        }
    }, [uploadVoice, recordedVoice])

    useEffect(() => {
        if (selectedSlideIndex !== -1) {
            setTtsText(selectedSlide.audio.meta.text)
            setSelectedAudio(selectedSlide.audio.originalSrc)
        }
    }, [selectedSlideIndex])




    useEffect(() => {
        fetchUploadVoice()
    }, [])

    return (
        <>
            <div className="voice-block-mid">
                <textarea
                    placeholder="Add text here"
                    onChange={(e) => onEditTts(e)}
                    value={ttsText}
                />

            </div>

            <div className='row'>
                <div className='col-md-12'>
                    {loader.generate ?
                        <div className="text-center mt-2">
                            <i className='fa fa-spin fa-spinner' style={{ fontSize: 20, color: themeColor }} />
                        </div> :
                        generatedVoice ?
                            <div>
                                <div className='d-flex justify-content-between p-2 mt-2'>
                                    <h6 >Generated clone voice</h6>
                                    <div>
                                        {play ?
                                            <MdOutlineStopCircle style={{ color: themeColor, fontSize: 20, cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                                            <MdOutlinePlayCircle style={{ color: themeColor, fontSize: 20, cursor: "pointer" }} title='Play' onClick={handlePlay} />
                                        }
                                        <FaCheck style={{ fontSize: 20, cursor: "pointer" }} title='Use' className='ms-2' onClick={handleApply} />
                                    </div>
                                </div>
                                <label htmlFor='addTtsAll' className='d-flex'>
                                    <input
                                        type="checkbox"
                                        id='addTtsAll2'
                                        onChange={handleGenerateAll}
                                        style={{ width: '40px', height: "19px", }}
                                    />
                                    <span className='align-self-center' style={{ fontSize: '13px' }}>Use the same voice to generate voiceover for all the slides</span>
                                </label>
                            </div>
                            :
                            editor.voiceClone.processLoader ?
                                <div className='text-center'>
                                    <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} />
                                </div>
                                : ""

                    }
                </div>
            </div>

            <div className="campaign-name-inp">

                <div className="clone-step">
                    {/* <label className="pt-3 pb-3"><span>Upload Voice Sample</span></label> */}
                    <div className="fileUpload alt d-block text-center mt-2">
                        {loader.upload && activeUploadType === "voiceover" ?
                            <div class="progress" style={{ width: "100%" }}>
                                <div class="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: themeColor }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{percent}%</div>
                            </div>
                            : <>
                                <FiUpload />
                                <p className="text-center"><strong>Upload Voice Sample</strong> <br /> <a href="">Click to Browse</a> or Drag and Drop</p>
                                <input
                                    type="file"
                                    accept=".mp3"
                                    onChange={(e) => onUploadVoice(e, "voiceover")}
                                />
                            </>}
                    </div>

                    <div className="or mt-4 mb-4"><span>OR</span></div>
                    <label className="pb-3"><span>Record Voice</span></label>

                    <Recording
                        onInputFile={onUploadVoice}
                        uploadLoader={loader}
                        activeUploadType={activeUploadType}
                    />

                </div>
            </div>



            <div className="row">
                <div className="col-12 mt-5">
                    <h6>Your Voice Sample</h6>
                    {/* <p style={{ fontSize: 12, color: "#b5b5b5" }}>click on Tick mark (<FaCheck />) to select the voice sample for voice cloning</p> */}
                    <div className="innertab-scroll" id="scrollableMediaImage" >
                        <div className="row">
                            <div className="mediaList forAudio">
                                <ul>
                                    {voices.length > 0 ?
                                        voices.map((curElem, index) => {
                                            return (
                                                <AudioFiles
                                                    voiceOverName={curElem.name.length > 15 ? `${curElem.name.slice(0, 14)}...` : curElem.name}
                                                    title={type === "music" ? curElem.name.length > 15 ? `${curElem.name.slice(0, 14)}...` : curElem.name : "Uploaded VoiceOver"}
                                                    selectedSlideIndex={selectedSlideIndex}
                                                    url={curElem.url}
                                                    type="uploaded"
                                                    newType="cloneVc"
                                                    handleUse={handleUse}
                                                    removeMedia={removeMedia}
                                                    selectedAudio={selectedAudio}
                                                    setSelectedAudio={setSelectedAudio}
                                                    currentUrl={currentUrl}
                                                    setCurrentUrl={setCurrentUrl}
                                                    key={index}
                                                    id={curElem.id}
                                                />)
                                        }) :
                                        <li>
                                            {
                                                loader.fetch ?
                                                    <div className="col-md-12">
                                                        < h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: themeColor }} /></h4>
                                                    </div>
                                                    : ''
                                            }
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div >
            </div >



        </>
    )
}

export default VoiceClone
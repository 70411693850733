import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import closeModalx from "../../images/modal-close.png"
import TimezoneSelect from 'react-timezone-select'
import { useDispatch, useSelector } from 'react-redux'
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";
import facebookIcon from "../../images/logo-facebook.svg"
import youtubeIcon from "../../images/YT-DEFAULT.png"

import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useImmer } from 'use-immer'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'
import { onUpdateSchedule } from '../../actions/contentAction'

const ScheduleModal = ({ show, handleClose, vData }) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const social = useSelector(state => state.social)
    const [socialAcc, setSocialAcc] = useState([])

    const [data, setData] = useImmer({
        scheduleDate: new Date(),
        socialAccounts: [],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        videoData: false
    })

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    }


    const handleSocial = (obj) => {
        let val = {
            id: false,
            type: ""
        }
        if (obj.appName) {
            val.id = obj.id
            val.type = obj.appName
        } else {
            val.id = obj.id
            val.type = obj.type
        }
        let isIndex = data.socialAccounts.findIndex(({ id, type }) => id === val.id && (type === val.type || type === val.appName))

        if (isIndex !== -1) {
            setData((draft) => {
                draft.socialAccounts.splice(isIndex, 1)
            })
        }
        else {
            setData((draft) => {
                draft.socialAccounts.push(val)
            })
        }

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let obj = { ...data }
        obj.videoData = vData
        dispatch(onUpdateSchedule(obj, handleClose, setLoader))
    }

    useEffect(() => {
        let arr = []
        if (social) {
            Object.keys(social).forEach((curElem) => {
                if (social[curElem].length > 0 && curElem !== "sonority" && curElem !== "videoreel") {
                    social[curElem].forEach((data) => {
                        arr = [...arr, data]
                    })
                }
            })
            setSocialAcc(arr)
        }
    }, [social])

    return (
        <Modal className="VideoModal VideoModal-forintro talking-instruction scVideo" show={show} onHide={handleClose} centered>
            <Modal.Body style={{ color: '#ffffff' }}>
                <div className="schedule-video">
                    <div onClick={handleClose} className="vidClose"><img src={closeModalx} alt="" /></div>
                    <h2 className='pb-2'>Schedule Your Video</h2>
                    <hr className='mt-0' />


                    <div className='row'>
                        <div className='col-sm-12 pt-3'>
                            <div className='common-input-wrap mt-0'>
                                <label htmlFor="">Date & Time</label>
                                <DatePicker
                                    className='common-input'
                                    selected={data.scheduleDate}
                                    minDate={new Date()}
                                    onChange={(date) => handleChange("scheduleDate", date)}
                                    showTimeSelect={true}
                                    timeIntervals={1}
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                />
                            </div>
                        </div>

                        {/* <div className='col-sm-6 pt-3'>
                            <label htmlFor="">Time</label>
                            <TimePicker
                                className='common-input'
                                onChange={(value) => handleChange("time", value)}
                                value={data.time}
                                maxDetail="second"
                            />
                        </div> */}
                    </div>


                    <div className='common-input-wrap mb-2 pt-3 z-index'>
                        <label htmlFor="">Timezone</label>
                        <TimezoneSelect
                            value={data.timezone ? data.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone}
                            onChange={(e) => handleChange("timezone", e.value)}
                            className='common-inputb p-0'
                        />
                    </div>
                    <div className='mb-2 pt-3'>
                        <label htmlFor="">Social Accounts</label>
                        <div className='sc-social-accounts'>
                            {socialAcc.length > 0 ?
                                socialAcc.map((curElem, index) => {
                                    let image = curElem.type === "youtube" ? youtubeIcon :
                                        curElem.type === "facebook" ? facebookIcon :
                                            curElem.appName === "ScriptReel" ? scriptIcon :
                                                curElem.appName === "LiveReel" ? liveIcon :
                                                    curElem.appName === "Syvid" ? syvidIcon :
                                                        curElem.appName === "TrimReel" ? trimIcon : ""
                                    return (
                                        <div key={index} className='sc-social-accounts-single' style={{ position: "relative" }} onClick={() => handleSocial(curElem)}>
                                            <div className='sc-social-accounts-img'><img src={image} alt='' /></div>
                                            <p className='text-light'>{curElem.username}</p>
                                            {data.socialAccounts.findIndex(({ id, type }) => id === curElem.id && (type === curElem.type || type === curElem.appName)) !== -1 ?
                                                <div className="slide-active">
                                                    <div className='slide-check'><IoCheckmarkDoneOutline /></div>
                                                </div>
                                                : null}
                                        </div>
                                    )
                                })
                                : ""}
                        </div>
                    </div>
                    <div className='text-right'>
                        <button className='demoLink' onClick={handleClose}><span>Cancel</span></button>
                        <button className='demoLink ms-2' onClick={handleSubmit}><span>{loader ? <>Submitting <i className='fa fa-spin fa-spinner' /></> : "Submit"}</span></button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ScheduleModal
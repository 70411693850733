import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IoPauseOutline } from "react-icons/io5";
import { IoMicOffOutline, IoMicOutline, IoTrashOutline } from 'react-icons/io5'
import { useDrag } from '@use-gesture/react'
import { onDragLeftTile, onDragRightTile } from '../../../actions/contentAction';
import { useDispatch } from 'react-redux';
import { MdOutlineCancel } from "react-icons/md";
import { themeColor } from '../../../global/global';
const Tile = ({ curElem, index, onSelectTile, handleTts, selectInnerBackground, handleDeleteAlert, handleDelete }) => {
    // console.log(curElem)
    const dispatch = useDispatch()
    const [dragActive, setDragActive] = useState(false)
    const [devider, setDevider] = useState({
        left: 0,
        right: 0,
        tile: 0
    })

    const updateNodeWidthLeft = useDrag((params) => {
        // let movementVal = +params.movement[0].toFixed()
        // let deviderVal = +devider.left.toFixed()
        // let xAxisVal = +curElem.style.xVal.toFixed()
        // let lastWidth = +curElem.style.width.toFixed()
        // let data = { ...state }

        // if (xAxisVal + movementVal - deviderVal >= 0) {
        //     if (data.items[rowIndex].length > 1) {
        //         if (colIndex === 0) {
        //             data.items[rowIndex][colIndex].style.xVal = xAxisVal + movementVal - deviderVal
        //             data.items[rowIndex][colIndex].style.width = lastWidth - movementVal + deviderVal
        //         }
        //         else if (data.items[rowIndex][colIndex - 1].style.xVal + data.items[rowIndex][colIndex - 1].style.width < xAxisVal + movementVal - deviderVal && params.direction[0] === -1) {
        //             data.items[rowIndex][colIndex].style.xVal = xAxisVal + movementVal - deviderVal
        //             data.items[rowIndex][colIndex].style.width = lastWidth - movementVal + deviderVal
        //         } else if (params.direction[0] === 1) {
        //             data.items[rowIndex][colIndex].style.xVal = xAxisVal + movementVal - deviderVal
        //             data.items[rowIndex][colIndex].style.width = lastWidth - movementVal + deviderVal
        //         }
        //     } else {
        //         data.items[rowIndex][colIndex].style.xVal = xAxisVal + movementVal - deviderVal
        //         data.items[rowIndex][colIndex].style.width = lastWidth - movementVal + deviderVal
        //     }
        // }
        // if (params.active) {
        //     setDevider({
        //         ...devider,
        //         left: movementVal
        //     })
        // }
        // else {
        //     setDevider({
        //         ...devider,
        //         left: 0
        //     })
        // }


        let movementVal = +params.movement[0].toFixed()
        let deviderVal = +devider.left.toFixed()
        let lastWidth = +curElem.timelineData.width
        let xAxisVal = +curElem.timelineData.xVal.toFixed()

        dispatch(onDragLeftTile(xAxisVal, movementVal, deviderVal, lastWidth, index))

        // if (lastWidth > 100 && params.direction[0] === -1) {
        //     dispatch(onDragLeftTile(movementVal, deviderVal, lastWidth, index))
        // } else {
        //     if (params.direction[0] === 1) {
        //         dispatch(onDragLeftTile(movementVal, deviderVal, lastWidth, index))
        //     }
        // }
        if (params.active) {
            setDevider({
                ...devider,
                right: movementVal
            })
        }
        else {
            setDevider({
                ...devider,
                right: 0
            })
        }

    })



    const updateNodeWidthRight = useDrag((params) => {
        let movementVal = +params.movement[0].toFixed()
        let deviderVal = +devider.right.toFixed()
        let lastWidth = +curElem.timelineData.width
        if (lastWidth > 100 && params.direction[0] === -1) {
            dispatch(onDragRightTile(movementVal, deviderVal, lastWidth, index))
        } else {
            if (params.direction[0] === 1) {
                dispatch(onDragRightTile(movementVal, deviderVal, lastWidth, index))
            }
        }
        if (params.active) {
            setDevider({
                ...devider,
                right: movementVal
            })
        }
        else {
            setDevider({
                ...devider,
                right: 0
            })
        }
    }
    )

    const onMouse = (isEnter) => {
        setDragActive(isEnter)
        // if(!isEnter){
        //     updateDuration
        // }
    }



    return (
        <Draggable
            key={index} draggableId={`id_${index}`} index={index}
            isDragDisabled={dragActive}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={index}
                    onClick={() => onSelectTile(index)}
                    className={`videoSlide-group ${+curElem.isSelected ? 'active' : ""}`}
                    style={{
                        ...provided.draggableProps.style, width: +curElem.timelineData.width,
                        // position: "absolute", left: curElem.timelineData.xVal
                    }}
                >

                    {curElem.audio.enable ?
                        <div
                            className="slide-icon"
                            onClick={(e) => handleTts(index, e)}
                        >
                            <IoMicOutline />
                        </div>
                        :
                        <div className="slide-icon"><IoMicOffOutline /></div>
                    }
                    <div
                        className="slide-icon right"
                        title='Delete Slide'
                        onClick={(e) => handleDeleteAlert(e, curElem, index)}
                    >
                        <IoTrashOutline />
                    </div>
                    {curElem.background.length > 0 ?
                        curElem.background.map((item, ind) => {
                            return (
                                <div
                                    className="videoSlide-single"
                                    style={{ position: "relative", background: item.type === "color" ? item.src : `url(${item.thumbnail})` }}
                                    key={ind}
                                    onClick={(e) => selectInnerBackground(index, ind)}
                                >
                                    {item.type === "video" ?
                                        <video
                                            src={item.src + "#t=5"}
                                            style={{ height: "100%", objectFit: "cover", width: "100%" ,}}
                                            muted
                                            autoPlay={false}
                                        />

                                        : null}
                                    {curElem.background.length > 1 ?
                                        <MdOutlineCancel
                                            className='slide-inner-icon'
                                            title='Delete Tile'
                                            onClick={(e) => handleDelete(e, index, ind)}
                                        /> : null}
                                    {/* {ind !== 0 ?
                                        <div
                                            className="slide-icon right"
                                            onClick={(e) => handleDeleteAlert(e, curElem, index)}
                                        >
                                            <IoTrashOutline />
                                        </div> : null} */}


                                </div>
                            )
                        })
                        : ""}


                    {/* <div
                        className='videoSlide-drag videoSlide-left-drag'
                        style={{ cursor: "col-resize" }}
                        {...updateNodeWidthLeft()}
                        onMouseEnter={() => setDragActive(true)}
                        onMouseLeave={() => setDragActive(false)}
                    >
                        <IoPauseOutline />
                    </div> */}

                    <div
                        className='videoSlide-drag videoSlide-right-drag'
                        style={{ cursor: "col-resize" }}
                        {...updateNodeWidthRight()}
                        onMouseEnter={() => onMouse(true)}
                        onMouseLeave={() => onMouse(false)}
                    >
                        <IoPauseOutline />
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default Tile
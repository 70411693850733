import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import img1 from "../../images/16-9.png";
import img2 from "../../images/1-1.png";
import img4 from "../../images/9-16.png";
import { BsArrowLeft, BsPlusLg } from "react-icons/bs"
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onFetchContent, onfetchCampaign } from "../../actions/contentAction";
import VideoSetCard from "./VideoSetCard";
import queryString from "query-string"
import { themeColor } from "../../global/global";

const VideoSet = ({ location }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth);
    const { id } = queryString.parse(location.search)

    const createdCamp = useSelector(state => state.content.createdCamp)

    const [state, setState] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [loaderSet, setLoaderSet] = useState(false)
    const fetchContentData = () => {
        let data = {
            "campaignId": id
        }
        setLoaderSet(true)
        dispatch(onFetchContent(data, setState, setLoaderSet, history))
    }


    const fetchCampaign = () => {
        let data = {
            campaignId: id
        }
        dispatch(onfetchCampaign(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchContentData()
            fetchCampaign()
        }
    }, [id])

    return (

        loader.fetch ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Video Sets" />
                <Navbar />
                <section className="siteWrap">
                    <div className="contWrap">
                        <div className="container">
                            <div className="contWrap-title">
                                <Link to='/dashboard' style={{ color: "white", textTransform: "capitalize" }}>
                                    <h2><BsArrowLeft /> {createdCamp.campaignName ? createdCamp.campaignName : ""}</h2>
                                </Link>
                            </div>
                            <div className="contWrap-list">
                                {state.length > 0 ?
                                    null :
                                    <div className="row text-center my-3">
                                        <div className="col-md-12">
                                            {loaderSet
                                                ?
                                                <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: "30px" }} />
                                                :
                                                <h5>You do not have any video set, To create one please click on the plus button.</h5>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    {
                                        state.length > 0 ?
                                            state.map((curElem, index) => {
                                                let img = ""
                                                if (curElem.dimension === "1920x1080") {
                                                    img = img1
                                                } else if (curElem.dimension === "1080x1920") {
                                                    img = img4
                                                } else if (curElem.dimension === "1080x1080") {
                                                    img = img2
                                                }
                                                return (
                                                    <VideoSetCard
                                                        curElem={curElem}
                                                        img={img}
                                                        index={index}
                                                        fetchContentData={fetchContentData}
                                                    />
                                                )
                                            }) : ""}
                                    <div className="col-xl-3 col-lg-4 col-sm-6">
                                        {
                                            +auth.user.isClientAccount === 1 ? "" :
                                                <Link
                                                    className="contWrap-list-single add"
                                                    to={`/create-videoset?id=${id}`}
                                                >
                                                    <div>
                                                        <BsPlusLg className="largeIcon" />
                                                    </div>
                                                </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </>
    )
}

export default VideoSet;
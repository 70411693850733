import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import VideoCard from "./VideoCard";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import ContentModal from "../createContent/ContentModal";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { fetchMergeCampaigns } from "../../actions/contentAction";
import MergeCard from "./reelMerge/MergeCard"
import { themeColor } from "../../global/global";
const Content = () => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [searchKey, setSearchKey] = useState("")
    const [totalCourses, setTotalCourses] = useState(0)
    const [type, setType] = useState("1")
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(false)
    const [mergeVideo, setMergeVideo] = useState([])
    const [memberShip, setMemberShip] = useState([])
    const [project, setProject] = useState([])

    const handleClose = () => setShow(false)




    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    const handleShow = () => {
        if (memberShip.length === 1 && memberShip[0] === "premium") {
            if (project.length >= 50) {
                dispatch(setAlert("You can create 100 maximum projects. To create more please upgrade your membership.", "warning"))
            } else {
                setShow(true);
            }
        } else {
            setShow(true);
        }
    }

    const handleChangeType = (val) => {
        setType(val)
    }


    const fetchMerge = () => {
        setLoader(true)
        setTotalCourses(0)
        dispatch(fetchMergeCampaigns(setMergeVideo, setTotalCourses, setLoader))
    }

    useEffect(() => {
        if (type === "2") {
            fetchMerge()
        }
    }, [type])

    return (
        <>
            <TitleBar title="Campaigns" />
            <Navbar />

            <section className="siteWrap">

                <div className="projectTitle-wrap">
                    <div className="container">
                        <div className="projectTitle">
                            <div className="projectTitle-left">
                                <h2>ALL CAMPAIGNS | <span>{totalCourses} Campaign{totalCourses >= 2 ? "s" : ''}</span> </h2>
                            </div>
                            <div className="projectTitle-right">
                                <div className="project-input-box">

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fas fa-search"></i></span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Campaign"
                                            onChange={(e) => setSearchKey(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {memberShip.includes("professional") || memberShip.includes("professional-lite") || memberShip.includes("bundle") || memberShip.includes("fastpass") ?

                                    <select
                                        className="merge-btn"
                                        value={type}
                                        onChange={(e) => handleChangeType(e.target.value)}
                                    >
                                        <option value={1}>Campaigns</option>
                                        <option value={2}>Merge Campaigns</option>
                                    </select>
                                    : ""}

                                {
                                    +auth.user.isClientAccount === 1 ? "" :
                                        <button className="demoLink" onClick={handleShow}><i className="fas fa-plus-circle"></i> Create New Campaign</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">

                    <div className="forproject">
                        {type === "1" ?
                            <VideoCard
                                project={project}
                                setProject={setProject}
                                searchKey={searchKey}
                                setTotalCourses={setTotalCourses}
                            /> :
                            <div className="row mt-2">
                                {mergeVideo.length > 0 ?
                                 mergeVideo.filter((curElem) => {
                                    if (searchKey) {
                                        return curElem.name.toLowerCase().includes(searchKey.toLowerCase())
                                    }
                                    else {
                                        return curElem
                                    }
                                }).length>0?
                                    mergeVideo.filter((curElem) => {
                                        if (searchKey) {
                                            return curElem.name.toLowerCase().includes(searchKey.toLowerCase())
                                        }
                                        else {
                                            return curElem
                                        }
                                    }).map((curElem, index) => {
                                        return (
                                            <MergeCard
                                                fetchMerge={fetchMerge}
                                                item={curElem}
                                                key={index}
                                            />
                                        )

                                    }):!loader ?
                                    <div className="no-campaign-card col-md-12">
                                        <div className="no-campaign-card-box text-center text-muted mt-3">
                                            <h6>Data Not Found</h6>
                                        </div>
                                    </div> : '' : !loader ?
                                        <div className="no-campaign-card col-md-12">
                                            <div className="no-campaign-card-box text-center text-muted mt-3">
                                                <h6>You do not have any "Merge Campaigns" in your account</h6>
                                            </div>
                                        </div> : ''
                                }
                                {loader ?
                                    <div className="col-md-12">
                                        <h4 className="text-center load-icon-help mt-3"><i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: "34px" }} /></h4>
                                    </div> : ''
                                }
                            </div>
                        }


                    </div>

                </div>

                <ContentModal
                    show={show}
                    handleClose={handleClose}
                />
            </section>

            <Footer />
        </>
    )
}

export default Content;
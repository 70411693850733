import React, { useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { onCheckRender, onUpdateName } from '../../actions/contentAction';
import videoThumb from '../../images/fav-new.png';
import PublishModal from "../project/PublishModal"
import { useSelector } from 'react-redux';
import ScheduleModal from '../CommonComponents/ScheduleModal';
import { baseURL } from '../../global/global';
import videoCard16x9 from "../../images/videoCard16x9.png"
import videoCard9x16 from "../../images/videoCard9x16.png"
import videoCard1x1 from "../../images/videoCard1x1.png"
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'


const VideoListCard = ({ curElem, handleDelete, fetchVideos }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [nameLoader, setNameLoader] = useState(false)
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [memberShip, setMemberShip] = useState([])


    const [state, setState] = useState({
        cardStatus: curElem.status,
        render: "",
        errorMessage: "",
        generatedSlides: 0,
        totalSlides: 0
    })
    // const [ocp, setOcp] = useState({
    //     loader: false,
    //     data: "",
    //     show: false
    // })
    const [nameData, setNameData] = useState({
        cName: "",
        cId: "",
        active: false,
        loader: false
    })


    const handleShow1 = () => setShow1(true)
    const handleClose1 = () => setShow1(false)

    const handleSubmit = () => {
        if (nameData.cName && curElem.name !== nameData.cName) {
            let data, epName
            epName = "update-video"
            data = {
                videoName: nameData.cName,
                videoId: nameData.cId
            }
            setNameLoader(true)
            dispatch(onUpdateName(epName, data, fetchVideos, setNameLoader, nameData, setNameData))
        } else {
            setNameData({
                ...nameData,
                cName: "",
                cId: "",
                active: false,
            })
        }
    }

    const handleChange = (e) => {
        setNameData({
            ...nameData,
            cName: e.target.value
        })
    }

    const handleShow = (data) => {
        setNameData({
            ...nameData,
            cName: data.name,
            cId: data.id,
            active: true
        })
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    // 0 => Script Approval Pending
    // 1 => Slide Generated
    // 2 => Video Rendered 
    // 3 => Video Failed Rendering
    // 4 => Awaiting Customization
    // 5 => Downloading Resources
    // 6 => Rendering
    // 7 => Waiting in Local Queue
    // 9 => Added to Queue


    const onRenderCheck = (renderInterval) => {
        let data = {
            type: "video",
            id: curElem.id
        }
        dispatch(onCheckRender(data, renderInterval, fetchVideos, state, setState))
    }

    const handleOCP = (id) => {
        window.open(`${baseURL}ocp/${id}`, '_blank');
        // setOcp({
        //     ...ocp,
        //     loader: true
        // })
        // dispatch(onOCP(id, ocp, setOcp))
    }

    useEffect(() => {
        if (curElem.status === "6" || curElem.status === "9" || curElem.status === "5" || curElem.status === "1") {
            const renderInterval = setInterval(() => {
                onRenderCheck(renderInterval)
            }, 3000)

            return () => {
                clearInterval(renderInterval)
            }
        }

    }, [])

    useEffect(() => {
        setState({
            ...state,
            cardStatus: curElem.status,
        })
    }, [curElem])


    return (
        <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="videoProject-single alt">
                <div className={`videoProject-single-top ${curElem.dimension === "1080x1920" ? "size-9-16" : curElem.dimension === "1080x1080" ? "size-1-1" : ""}`} >
                    <img src={curElem.thumbnail !== "" ? curElem.thumbnail : curElem.dimension === "1920x1080" ? videoCard16x9 : curElem.dimension === "1080x1920" ? videoCard9x16 : videoCard1x1} alt='' style={{ height: "100%", width: "100%", overflow: "hidden", objectFit: "cover" }} />
                    <div className={`video-card-wrapper-1 dashboard-card-bg ${state.cardStatus === "3" ? "dashboard-card-bg-2" : ""} p-0`} style={{ zIndex: 999 }} >
                        {/* {state.cardStatus === "2" ?
                            <img alt='' className="mb-3 vidThumb-img  img-fluid" style={{ height: "100%", width: "100%", }} src={curElem.thumbnail !== "" ? curElem.thumbnail : ""} />
                            : ""} */}
                        <div className="video-card-content">

                            <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} alt="" />

                            <p className={`font-italic mb-0 text-light ${state.cardStatus === "5" ? "mt-4" : ""} video-card-await-class`} >
                                {state.cardStatus === "0" ? "Script Approval Pending" :
                                    state.cardStatus === "1" ?
                                        <>
                                            Generating Slides
                                            <div className="render_wrapper mt-3 text-center" style={{ width: "100%", position: "relative" }}>
                                                <ProgressBar className='bg-warning ' variant="warning" animated now={parseInt((+state.generatedSlides / +state.totalSlides) * 100)} />
                                                <span className="progress-bar-text">{state.generatedSlides} / {state.totalSlides}</span>
                                            </div>
                                        </> :
                                        state.cardStatus === "9" ?
                                            <>
                                                Rendering Face
                                                <div className="render_wrapper mt-3 text-center" style={{ width: "100%", position: "relative" }}>
                                                    <ProgressBar className='bg-warning ' variant="warning" animated now={parseInt((state.render.includes("Face") ? +state.render.split(' ').at(-1).split("/")[0] : 0) / (state.render.includes("Face") ? +state.render.split(' ').at(-1).split("/")[1] : 0) * 100)} />
                                                    <span className="progress-bar-text">{state.render.includes("Face") ? +state.render.split(' ').at(-1).split("/")[0] : 0} / {state.render.includes("Face") ? +state.render.split(' ').at(-1).split("/")[1] : 0}</span>
                                                </div>
                                            </>
                                            :
                                            state.cardStatus === "3" ? "Video Failed Rendering" :
                                                state.cardStatus === "4" ? "Awaiting Customization" :
                                                    state.cardStatus === "5" ? "Downloading Resources" :
                                                        state.cardStatus === "6" ? "" :
                                                            ""}

                                {
                                    state.cardStatus === "5" ?
                                        <div className="render_wrapper mt-1" style={{ width: "100%" }}>
                                            <ProgressBar className='bg-warning ' variant="warning" animated now={100} />
                                        </div> : ""
                                }
                                {state.cardStatus === "6" ?
                                    <>
                                        <div className="render_text mt-4 d-flex justify-content-between" style={{ width: "100%" }}>
                                            <p style={{ color: '#ffff', fontWeight: "bold", fontSize: "14px" }}>Rendering progress</p>
                                            <p>{state.render}%</p>
                                        </div>
                                        <div className="render_wrapper mt-3" style={{ width: "100%" }}>
                                            <ProgressBar className='bg-warning ' variant="warning" animated now={state.render} />
                                        </div>
                                    </>
                                    : ""}
                            </p>


                        </div>
                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.created}</p></div>
                        <div className="vidiconAll">
                            <div className="vidHover-single">
                                {
                                    +auth.user.isClientAccount === 1 ? "" :
                                        <Link to={`${curElem.status === "0" ? `/content-set?id=${curElem.campaignId}&vsid=${curElem.videoSetId}&videoId=${curElem.id}` : `/editor?id=${curElem.id}`}`}>
                                            <span className="vidHover-icon"><i class="bi bi-pencil-square"></i></span>
                                            <span>Edit</span>
                                        </Link>
                                }
                            </div>
                            {curElem.status === "2" ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={curElem.videoUrl}>
                                            <span className="vidHover-icon"><i class="fa fa-arrow-circle-o-down" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    {
                                        +auth.user.isClientAccount === 1 ? "" :
                                            <>
                                                <div className="vidHover-single">
                                                    <a onClick={() => setShow(true)} className="cursor-pointer">
                                                        <span className="vidHover-icon"><i class="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                        <span>Publish</span>
                                                    </a>
                                                </div>
                                                <div className="vidHover-single">
                                                    <a onClick={handleShow1} className='cursor-pointer'>
                                                        <span className="vidHover-icon"><i class="bi bi-calendar-check"></i></span>
                                                        <span>Schedule</span>
                                                    </a>
                                                </div>
                                            </>
                                    }
                                    {
                                        memberShip.includes("enterprise") || memberShip.includes("bundle") ?
                                            <div className="vidHover-single">
                                                <a onClick={() => handleOCP(curElem.id)} className="cursor-pointer">
                                                    <span className="vidHover-icon">
                                                        <i class="fa fa-desktop" aria-hidden="true"></i>
                                                    </span>
                                                    <span>OCP</span>
                                                </a>
                                            </div>
                                            : ''
                                    }
                                </> : ""}
                            {
                                +auth.user.isClientAccount === 1 ? "" :
                                    state.cardStatus === "0" || state.cardStatus === "4" || state.cardStatus === "2" || state.cardStatus === "3" ?
                                        <div className="vidHover-single">
                                            <a onClick={() => handleDelete(curElem)} className='cursor-pointer'>
                                                <span className="vidHover-icon"><i class="bi bi-trash3-fill"></i></span>
                                                <span>Delete</span>
                                            </a>
                                        </div>
                                        : null
                            }

                        </div>
                    </div>
                </div>
                <div className="videoProject-single-bottom">
                    <div className="videoProject-single-edIcon cursor-pointer" onClick={() => handleShow(curElem)} >
                        {nameLoader ? <i className="fa fa-spinner fa-spin" /> : <i class="bi bi-pencil-square"></i>}
                    </div>
                    <div data-tooltip-id={`my-tooltip-${curElem.id}`} className="videoProject-single-edName text-light">
                        {curElem.logFile && JSON.parse(curElem.logFile)?.errorMessage && +state.cardStatus === 3 ?
                            <Tooltip
                                id={`my-tooltip-${curElem.id}`}
                                style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                                place="bottom"
                                content={JSON.parse(curElem.logFile)?.errorMessage}
                            />
                            : ""}
                        <span>
                            {nameData.active && +auth.user.isClientAccount === 0 ? <>
                                <input
                                    value={nameData.cName}
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e) => handleSubmit(e)}
                                />
                            </> :
                                curElem.name}
                        </span>
                    </div>
                </div>
            </div>
            <PublishModal
                curElem={curElem}
                show={show}
                setShow={setShow}
                memberShip={memberShip}
            />

            <ScheduleModal
                vData={curElem}
                show={show1}
                handleClose={handleClose1}
            />
        </div>
    )
}

export default VideoListCard
import React, { useEffect, useState } from 'react'
import { AudioVisualizer } from 'react-audio-visualize';
import { FiMusic } from 'react-icons/fi';

const SlideAudio = ({ curElem }) => {
    const [aud, setAud] = useState(false)

    useEffect(() => {
        if (curElem.audio) {
            if (curElem.audio.enable) {
                let au = new Audio(curElem.audio.src)
                fetch(au.src)
                    .then(response => response.blob())
                    .then((d) => setAud(d))
                    .catch((e) => console.log(e))
            }
        }
    }, [curElem])

    return (
        <div className="audioSlide-single" style={{ width: +curElem.duration * 100 }}>
            <div className="audio-meta" style={{ width: "100%" }}>
                <div className="audio-meta-left">
                    <div className="audio-meta-icon"><FiMusic /></div>
                    {curElem.audio.src ?
                        <span style={{ fontSize: 12 }}>{curElem.audio.src.slice(0, (+curElem.duration * 100 / 20))}...</span> : null}
                </div>
                {curElem.audio.src ?
                    <div className="audio-meta-right">{curElem.duration}</div> : null}
            </div>
            <div
            // className="audio-bg blue"
            >
                <AudioVisualizer
                    // ref={visualizerRef}
                    blob={aud}
                    width={(+curElem.duration * 100)}
                    height={50}
                    barWidth={2}
                    gap={0}
                    barColor={'#3c94d5'}
                />
            </div>
        </div>
    )
}

export default SlideAudio
import React, { useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { IoIosMic } from 'react-icons/io'
import { themeColor } from '../../global/global'
import { onUploadMedia } from '../../actions/CommonActions'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import VoiceRows from './VoiceRows'
import Recording from './Recording'
import { FaCheck } from 'react-icons/fa6'
import { setAlert } from '../../actions/alert'

const UploadVoiceClone = ({ setState, state, fetchUploadVoice, uploadVoice }) => {
    const dispatch = useDispatch()
    let allowedExt = ['audio/mpeg'];
    const [activeUploadType, setActiveUploadType] = useState("voiceover")

    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false
    })

    const onUploadVoice = (e, type) => {
        let allowedSize = 5000000
        setActiveUploadType(type)
        if (e.target.files.length > 0 && allowedExt.includes(e.target.files[0].type)) {
            let upFile = e.target.files[0]
            const reader = new FileReader();
            reader.readAsArrayBuffer(e.target.files[0]);
            reader.onloadend = (e) => {
                const ctx = new AudioContext();
                const audioArrayBuffer = e.target.result;
                ctx.decodeAudioData(audioArrayBuffer, data => {
                    // this is the success callback
                    const duration = data.duration;
                    if (+duration <= 60) {
                        if (upFile.size < allowedSize) {
                            const formData = new FormData()
                            formData.append('upload_type', type)
                            formData.append('file', upFile)
                            dispatch(onUploadMedia(formData, fetchUploadVoice, loader, setLoader, setPercent, false,))

                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Max allowed size for Music File is 5MB!',
                                confirmButtonColor: themeColor
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Maximum allowed duration is 60 seconds to clone your voice!',
                            confirmButtonColor: themeColor
                        })
                    }
                }, error => {
                    // this is the error callback
                    console.error(error);
                });
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have Selected Invalid File Type!',
                confirmButtonColor: themeColor
            })
        }
    }

    const handleUse = (url) => {
        if (state.voiceClone.url !== url) {
            setState((draft) => {
                draft.voiceClone.url = url
            })
            dispatch(setAlert("Voice applied successfully!", "success"))
        }
    }


    return (
        <div className="clone-step">
            {/* <label className="pt-3 pb-3"><span>Upload Voice Sample</span></label> */}
            <div className="fileUpload alt d-block text-center mt-2">
                {loader.upload && activeUploadType === "voiceover" ?
                    <div class="progress" style={{ width: "100%" }}>
                        <div class="progress-bar" role="progressbar" style={{ width: `${percent}%`, background: themeColor }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{percent}%</div>
                    </div>
                    : <>
                        <FiUpload />
                        <p className="text-center"><strong>Upload Voice Sample</strong> <br /> <a href="">Click to Browse</a> or Drag and Drop</p>
                        <input
                            type="file"
                            accept=".mp3"
                            onChange={(e) => onUploadVoice(e, "voiceover")}
                        />
                    </>}
            </div>




            <div className="or mt-4 mb-4"><span>OR</span></div>


            <label className="pb-3"><span>Record Voice</span></label>



            <Recording
                onInputFile={onUploadVoice}
                uploadLoader={loader}
                activeUploadType={activeUploadType}
            />
            {/* <IoIosMic />
                <p className="text-center">Start Recording</p> */}


            <div className="row">
                <div className="col-12 mt-5">
                    <h6>Your Voice Sample</h6>
                    <p style={{ fontSize: 12, color: "#b5b5b5" }}>click on Tick mark (<FaCheck />) to select the voice sample for voice cloning</p>
                    <div className="train_table mt-2">
                        <div className="table-area ">
                            <div className="table-responsive">
                                <table className="table widget-table text-light">
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                    {uploadVoice.length > 0 ?
                                        uploadVoice.map((curElem, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <VoiceRows
                                                        curElem={curElem}
                                                        index={index}
                                                        fetchVoice={fetchUploadVoice}
                                                        handleUse={handleUse}
                                                        selectedUrl={state.voiceClone.url}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td colSpan={5} className='text-center' style={{ borderBottom: "none" }}>
                                                {loader.fetch ?
                                                    <i className="fa fa-spin fa-spinner fetch-loader" /> : "No voice uploaded yet!"}
                                            </td>
                                        </tr>

                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UploadVoiceClone
import React from 'react'
import Navbar from "../Navbar";
import Footer from "../Footer";
import SettingMenu from './SettingMenu'
import SettingContent from './SettingContent'
import TitleBar from "../TitleBar";

const Settings = () => {
  return (
    <>
      <TitleBar title="settings" />
      <Navbar />

      <section className="siteWrap">

          <div className="tabCont container pt-5">
            <div className="row">
              <div className="col-lg-3 tabLeft">
                <div className="tabCont-left">
                  <SettingMenu />
                </div>
              </div>

              <SettingContent />


            </div>
          </div>
      </section>

      <Footer />
    </>
  )
}

export default Settings
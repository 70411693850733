
import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import rgbHex from "rgb-hex";
import hexRgb from "hex-rgb";
import { colorObj } from '../../../global/FontFamilyData';
import { addTextHighlight, removeHighlightColor, updateTextHighlight } from '../../../actions/contentAction';

const InlineColorPicker = ({ type, dataHigh, slideIndex, layerIndex, selectedText }) => {
    const dispatch = useDispatch()
    const [color, setColor] = useState({
        enable: false,
        hex: "#FFFFFFFF",
        r: 0,
        g: 0,
        b: 0
    });



    const onInputColor = (e) => {
        if (dataHigh.text !== "") {
            let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
            let hex = `#${rgbHex(rgba)}`
            setColor({
                ...color,
                hex: hex,
                r: e.rgb.r,
                g: e.rgb.g,
                b: e.rgb.b
            })
            const arrIndex = selectedText.textToHighlight.findIndex(({ text }) => text === dataHigh.text)
            if (arrIndex === -1) {
                let data = {
                    text: dataHigh.text,
                    color: type === "color" ? hex : dataHigh.color,
                    background: type === "bgcolor" ? hex : dataHigh.background
                }
                dispatch(addTextHighlight(data, slideIndex, layerIndex));
            }
            else {
                dispatch(updateTextHighlight(hex, type, arrIndex, slideIndex, layerIndex));
            }
        }
    }

    const onChangeColor = (color, index) => {
        if (dataHigh.text !== "") {
            const arrIndex = selectedText.textToHighlight.findIndex(({ text }) => text === dataHigh.text)

            if (index === 0) {
                removeBgColor(arrIndex)
            }
            else {
                let hex = `#${rgbHex(color.val)}`
                setColor({
                    ...color,
                    hex: hex
                })
                if (arrIndex === -1) {
                    let data = {
                        text: dataHigh.text,
                        color: type === "color" ? hex : dataHigh.color,
                        background: type === "bgcolor" ? hex : dataHigh.background
                    }
                    dispatch(addTextHighlight(data, slideIndex, layerIndex));
                }
                else {
                    dispatch(updateTextHighlight(hex, type, arrIndex, slideIndex, layerIndex));
                }
            }
        }
    }

    useEffect(() => {
        if (color.hex) {
            let hex = hexRgb(color.hex)
            setColor({
                ...color,
                r: hex.red,
                g: hex.green,
                b: hex.blue
            })
        }
    }, [color.hex])

    const removeBgColor = (index) => {
        setColor({
            ...color,
            r: 0,
            g: 0,
            b: 0
        })
        dispatch(removeHighlightColor(type, index, slideIndex, layerIndex));
    }

    return (
        <>
            <div className="fontStyle-block">
                <div className="row">
                    <div className="col-6">
                        <div className="inpWrap">
                            <input
                                className="pl-5 text-uppercase"
                                type="text"
                                value={color.hex}
                                readOnly
                            />
                            <span
                                className="colorValue cursor-pointer"
                                id="t-color-btn"
                                style={{ background: color.hex }}
                                onClick={() => setColor({
                                    ...color,
                                    enable: true
                                })}
                            ></span>
                            <span className="label">HEX</span>
                            {color.enable ?
                                <>
                                    <div className="swatch-cover"
                                        onClick={(e) => setColor({
                                            ...color,
                                            enable: false
                                        })}
                                    />
                                    <SketchPicker
                                        color={color.hex}
                                        onChange={(e) => onInputColor(e)}
                                    />
                                </>
                                : null}
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={color.r} readOnly />
                            <span className="label">R</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={color.g} readOnly />
                            <span className="label">G</span>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="inpWrap">
                            <input className="text-center" type="text" value={color.b} readOnly />
                            <span className="label">B</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="color-palette">
                <ul>
                    {colorObj.map((curElem, index) => {
                        return (
                            <li key={index} style={{ position: "relative", overflow: "hidden" }}>
                                <div
                                    style={{ background: curElem.val }}
                                    className="color-palette-single cursor-pointer"
                                    onClick={() => onChangeColor(curElem, index)}

                                >
                                    {index === 0 ? <div className="remove_line"></div> : ""}

                                </div>
                            </li>
                        )
                    })}


                </ul>
            </div>
        </>
    );
}

export default InlineColorPicker;

import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Rnd } from "react-rnd";
import { endAnimation } from '../../../actions/contentAction';
import { FaSpinner } from 'react-icons/fa';

const VideoLayer = (props) => {
    let ediotWidth = props.dimension === "1080x1920" ? 360 : props.dimension === "1920x1080" ? 853 : 540
    let ediotHeight = props.dimension === "1080x1920" ? 640 : props.dimension === "1920x1080" ? 480 : 540
    let newLeft = (props.layer.position.left * ediotWidth) / 100
    let newTop = (props.layer.position.top * ediotHeight) / 100

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const videoRef = useRef(null)
    const [myStyle, setMyStyle] = useState({
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        border: props.layer.style.border,
        boxShadow: props.layer.style.boxShadow,
        animationDuration: props.layer.animation.duration,
        animationDelay: props.layer.animation.delay,
        objectFit: props.layer.style.objectFit,
        clipPath: props.layer.style.clipPath,
        borderRadius: props.layer.style.borderRadius,
        objectPosition: props.layer.style.objectPosition,
        opacity: props.layer.style.opacity,
        transform: props.layer.style.transform,
        position: "absolute",
    })

    const handleAnimEnd = () => {
        dispatch(endAnimation(props.selectedSlideIndex, props.layerIndex))
    }

    const handleOnLoadVideo = () => {
        setLoader(false)
    }

    useEffect(() => {
        setLoader(true)
    }, [props.layer.src]);

    useEffect(() => {

        let newLeft = (props.layer.position.left * ediotWidth) / 100
        let newTop = (props.layer.position.top * ediotHeight) / 100
        const obj = {
            left: newLeft,
            top: newTop,
            width: `${props.layer.size.width}%`,
            height: `${props.layer.size.height}%`,
            border: props.layer.style.border,
            boxShadow: props.layer.style.boxShadow,
            animationDuration: props.layer.animation.duration,
            animationDelay: props.layer.animation.delay,
            objectFit: props.layer.style.objectFit,
            clipPath: props.layer.style.clipPath,
            borderRadius: props.layer.style.borderRadius,
            objectPosition: props.layer.style.objectPosition,
            opacity: props.layer.style.opacity,
            transform: props.layer.style.transform,
            position: "absolute",
        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size, props.layer.animation])

    const videoStyle = {
        filter: myStyle.filter,
        border: myStyle.border,
        borderRadius: myStyle.borderRadius,
        animationDelay: myStyle.animationDelay,
        animationDuration: myStyle.animationDuration,
        clipPath: myStyle.clipPath,
        width: "100%",
        height: "100%"
    }

    return (
        <>
            <Rnd
                style={{ overflow: "hidden", textAlign: "center", padding: 0 }}
                enableUserSelectHack={true}
                enableResizing={props.layer.isSelected ? true : false}
                disableDragging={props.layer.isSelected ? false : true}
                onClick={() => props.handleSelectLayer(props.layerIndex)}
                default={{ x: 0, y: 0, height: myStyle.height }}
                size={{
                    height: `${props.layer.size.height}%`,
                    width: `${props.layer.size.width}%`
                }}
                // size={{
                //     height: myStyle.height ? myStyle.height : 150,
                //     width: myStyle.width ? myStyle.width : 280
                // }}
                // position={{ x: myStyle.left, y: myStyle.top }}
                position={{ x: newLeft, y: newTop }}
                onDragStop={(e, d) => {
                    let rndLeft = (d.x / ediotWidth) * 100
                    let rndTop = (d.y / ediotHeight) * 100
                    // setMyStyle({ ...myStyle, left: rndLeft, top: rndTop })
                    props.handleRePosition(rndTop, rndLeft, props.layerIndex)
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    let rndLeft = (position.x / ediotWidth) * 100
                    let rndTop = (position.y / ediotHeight) * 100
                    // setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height, top: rndTop, left: rndLeft });
                    props.handleResize(ref.style.width, ref.style.height, rndLeft, rndTop, props.layerIndex)
                }}
                bounds=".canvasEditor-main"
                className={props.layer.isSelected ? 'layer-border' : ''}
            >
                {
                    loader ?
                        <div className='media-loader'>
                            <FaSpinner className='spin' />
                        </div>
                        : ""
                }
                <video
                    src={props.layer.src}
                    ref={videoRef}
                    autoPlay={true}
                    loop={true}
                    style={videoStyle}
                    muted={props.layer.mute}
                    onPlay={() => handleOnLoadVideo()}
                    onAnimationEnd={() => handleAnimEnd()}
                    className={`animate__animated ${props.layer.animation.playAnimation ? props.layer.animation.name : ""} ${loader ? 'invisible' : ""}`}
                    onClick={() => props.handleSelectLayer(props.layerIndex)}
                />
            </Rnd>
        </>
    )
}

export default VideoLayer
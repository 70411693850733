import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getTempVarient, onVariationChange } from '../../../actions/contentAction'

const TemplateVarient = ({ setPreLoader, selectedSlide, selectedSlideIndex }) => {
    const dispatch = useDispatch()
    const content = useSelector(state => state.content)
    const [variation, setVariation] = useState([])
    const [loader, setLoader] = useState(false)

    const [state, setState] = useState({
        selectdId: -1,
    })
    const handleSelectTemplate = (id) => {
        if (selectedSlide.variationId !== id) {
            setState({
                ...state,
                selectdId: id
            })
            let data = {
                slideId: selectedSlide.id,
                newVariationId: id
            }
            dispatch(onVariationChange(data, selectedSlide.timelineData, selectedSlideIndex, setPreLoader))
        }
    }
    const onGetVariation = (id) => {
        setLoader(true)
        dispatch(getTempVarient(id, setVariation, setLoader))
    }


    useEffect(() => {
        if (content.campaign.templateId) {
            console.log(content.campaign.templateId)
            onGetVariation(content.campaign.templateId)
        }
    }, [content.campaign.templateId])

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title mb-4"><h2>Template Variations</h2></div>
                <ul className='tempList'>
                    {
                        variation.length > 0 ?
                            variation.filter((obj) => {
                                return (content.campaign.dimension === obj.dimension)
                            }).map((curElem, index) => {
                                return (
                                    <li
                                        key={index}
                                        title={`VariationID -  ${curElem.id}`}
                                        style={{ cursor: "pointer", position: "relative", marginBottom: "9px" }}
                                        onClick={() => handleSelectTemplate(curElem.id)}
                                    >
                                        <div className='tempList-single'>
                                            <div className='tempList-img'><img src={curElem.previewImage} alt={`Variation ${index + 1}`} /></div>
                                            {curElem.id === selectedSlide.variationId ?
                                                <div className="slide-active">
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </li>
                                )
                            }) : ""
                    }
                    {
                        loader ?
                            <div className='col-md-12' style={{ textAlign: "center" }}>
                                <i className="fa fa-spinner fa-spin" style={{ color: "#AC46B4", fontSize: 25 }} />
                            </div>
                            : ""
                    }
                </ul>


            </div>
        </div >
    )
}

export default TemplateVarient
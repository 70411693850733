import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import waveIcon from "../../images/wave.svg";
import { addBgMusic, removeBgAudioFromSlides, setTtsData, removeTtsFromSlides } from "../../actions/contentAction";

let audio = false;
const AudioFiles = ({ title, url, currentUrl, setCurrentUrl, type, newType, handleUse, selectedAudio, setSelectedAudio, removeMedia, id, selectedSlideIndex, voiceOverName }) => {

    const dispatch = useDispatch();

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');

    let clearPause;
    const playAudio = (e, url) => {
        e.stopPropagation()
        if (audioDuration > 0) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }
            setLoader(true);
            audio = new Audio(url);
            audio.play()
                .then(r => setLoader(false)).catch(err => console.log(err));
            setCurrentUrl(url);
            setPlay(true)
            clearPause = setTimeout(() => {
                pauseAudio(false, url)
            }, audioDuration * 1000)
        }
    }

    const pauseAudio = (e, url) => {
        if (e) {
            e.stopPropagation()
        }
        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }


    const selectAudio = (url, timeDur) => {
        if (newType) {
            handleUse(url)
        } else {
            if (audio !== false) {
                if (url === audio.src) {
                    pauseAudio()
                }
            }
            let dur = audioDuration
            if (dur > parseInt(audioDuration.toFixed())) {
                dur = parseInt(audioDuration.toFixed()) + 1
            }
            if (title === "Uploaded VoiceOver") {
                const audio = {
                    src: url,
                    duration: parseInt(dur.toFixed()),
                    meta: {
                        languageId: "",
                        text: "",
                        translateText: "",
                        voiceId: "",
                    }
                }
                setSelectedAudio(url);
                dispatch(setTtsData(audio, selectedSlideIndex, true))
            }
            else {
                setSelectedAudio(url);
                dispatch(addBgMusic(url, selectedSlideIndex, timeDur))
            }
        }
    }

    const removeAudio = () => {
        setSelectedAudio(false);
        if (title === "Uploaded VoiceOver") {
            dispatch(removeTtsFromSlides(selectedSlideIndex))
        }
        else {
            dispatch(removeBgAudioFromSlides(selectedSlideIndex))
        }
    }

    const onConfirm = async () => {
        setButtonText('Deleting...');
        await removeMedia(id, setShowDelete, setButtonText);
    }

    const deleteFile = (e) => {
        e.stopPropagation()
        setShowDelete(true);
    }

    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])



    useEffect(() => {
        if (url) {
            let au = document.createElement('audio');
            au.src = url;

            au.addEventListener('loadedmetadata', function () {
                if (au.duration !== Infinity && au.duration !== 0) {
                    setAudioDuration(Number((au.duration).toFixed(1)));
                    au = null
                }
                else {
                    var getDuration = function (url, next) {
                        var _player = new Audio(url);
                        _player.load();
                        _player.currentTime = 24 * 60 * 60; // fake big time
                        _player.volume = 0;
                        _player.muted = false;
                        _player.play();

                        _player.addEventListener("durationchange", function (e) {
                            if (this.duration != Infinity) {
                                var duration = this.duration
                                _player.remove();
                                next(duration);
                            };
                        }, false);

                        // Wait for a user gesture (e.g., a click) before loading and playing the audio.
                        document.addEventListener("click", function () {
                            _player.load();
                            _player.currentTime = 24 * 60 * 60; // fake big time
                            _player.volume = 0;
                            _player.muted = false;
                            _player.play();
                        }, { once: true }); // Remove the event listener after it's triggered once.
                    };
                    if (au.src) {
                        getDuration(au.src, function (duration) {
                            setAudioDuration(Number((duration).toFixed(1)));
                            au = null
                        });
                    }
                }
            }, false);
        }
    }, [url])


    return (
        <>
            <li className="cursor-pointer" onClick={() => {
                selectedAudio === url ?
                    removeAudio(url) :
                    selectAudio(url, audioDuration)
            }}>
                <div className={`audioList-single card ${selectedAudio === url ? 'active-audio' : ''}`}>
                    <div className="active-audio-wrapper cursor-pointer">
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    {
                        type === "uploaded" ?
                            <div className="card-icon-delete delete-audio" onClick={(e) => deleteFile(e)} style={{ zIndex: 1, right: "1px", left: "none" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                            </div>
                            : ''
                    }
                    <div className="audioList-bottom">
                        {
                            play && currentUrl === url ?
                                <i
                                    className="fa fa-pause-circle"
                                    aria-hidden="true"
                                    onClick={(e) => pauseAudio(e, url)}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                />
                                :
                                <i
                                    className={`${loader ? 'fa fa-spinner fa-spin' : 'fas fa-play-circle'} `}
                                    onClick={(e) => playAudio(e, url)}
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                />
                        }
                        {
                            title === "Uploaded VoiceOver" ? <p className="mx-2 text-light">{voiceOverName}</p> : <p className="mx-1 text-light">{title}</p>

                        }

                        <span className="audio-time text-light">{`${audioDuration} sec`}</span>

                    </div>
                </div>

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this file
                </SweetAlert>
            </li>



        </>
    )
}

export default AudioFiles;
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onCreateName } from '../../actions/contentAction';
import modalCloseIcon from '../../images/modal-close.png';

const ContentModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [loader, setLoader] = useState(false)
    const [contentName, setContentName] = useState({
        text: "",
        len: 0
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { "campaignName": contentName.text }
        setLoader(true)
        dispatch(onCreateName(data, history, setLoader))
    }

    const handleChange = (val) => {
        setContentName({
            ...contentName,
            text: val,
            len: val.length
        })
    }
    return (
        <Modal className="VideoModal small" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} alt=""/></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <div className="inpField">

                        <label>Enter Campaign Name <span style={{ float: "right", fontWeight: "normal" }}>{contentName.len} / 100</span></label>
                        <input
                            type="text"
                            className="inpField-inp text-light my-2"
                            placeholder="Campaign Name"
                            onChange={(e) => handleChange(e.target.value)}
                            required
                            maxLength={100}
                        />
                    </div>
                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">Next {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ContentModal
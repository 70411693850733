import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import TitleBar from "../TitleBar";
import Audio from "./audio/Audio"
import Preview from "./preview/Preview";
import VideoTiles from "./videoTiles/videoTiles";
import Scripts from "./scripts/Scripts";
import Media from "./media/Media";
import BackgroundMedia from "./backgroundMedia/BackgroundMedia";
import Setting from "./setting/Setting";
import Credits from "./credits/Credits";
import Transition from "./transition/Transition";
import Animation from "./animation/Animation";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import VoiceOver from "./voiceOver/VoiceOver";
import { checkCloneVoice, onCheckInnerStatus, onFetchVideo, onRender, onUnmountEditor, onUpdateCampName } from "../../actions/contentAction";
import { useHistory } from "react-router-dom";

import { LuVideotape } from "react-icons/lu";
import { BiBookBookmark } from "react-icons/bi";
import { BsTextareaT, BsImages } from "react-icons/bs"
import { GrMultimedia } from "react-icons/gr";
import { MdOutlineRecordVoiceOver, MdAnimation } from "react-icons/md";
import { HiOutlineMusicNote } from "react-icons/hi";
import { PiCubeTransparentLight } from "react-icons/pi";
import { IoClose, IoSettingsOutline } from "react-icons/io5";
import { CgFilm } from "react-icons/cg";
import { RiUserFill } from "react-icons/ri"
import TalkingHead from "./TalkingHead/TalkingHead";
import Text from "./text/Text";
import { HiTemplate } from "react-icons/hi";
import { IoEyeOutline } from "react-icons/io5";
import { FaAnglesRight } from "react-icons/fa6";
import Template from "./Templates/Template";


const Editor = ({ location }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { id } = queryString.parse(location.search)
  const content = useSelector(state => state.content)
  const editor = useSelector(state => state.editor)

  const [loader, setLoader] = useState(true)
  const [renderLoader, setRenderLoader] = useState(false)
  const [preLoader, setPreLoader] = useState(false)
  const [memberShip, setMemberShip] = useState([])
  const [active, setActive] = useState({
    music: false,
    transition: false
  })
  const [generatingText, setGenerationText] = useState("")
  const [isActive, setIsActive] = useState(false);
  const [togglePanel, setTogglePanel] = useState(false);

  const [campName, setCampName] = useState("")

  const [showPreview, setShowPreview] = useState(false)
  const handlePreview = (val) => {
    if (val === "toggle") {
      setShowPreview((pre) => !pre)
    } else {
      setShowPreview(val)
    }
  }

  const handleActive = (type) => {
    if (type === "music" && active.music === false) {
      setActive({
        ...active,
        music: true
      })
    }
    else if (type === "transition" && active.transition === false) {
      setActive({
        ...active,
        transition: true
      })
    }
  }

  const handlePush = () => {
    history.push('/dashboard')
  }


  const handleRender = () => {
    const data = {
      id: id,
      type: "render",
      vsid: content.campaign.videoSetId,
      campId: content.campaign.campaignId
    }
    setRenderLoader(true)
    dispatch(onRender(data, setRenderLoader, history))
  }


  const handleSubmit = (e) => {
    let data = {
      videoName: campName,
      videoId: id
    }
    dispatch(onUpdateCampName(data))
  }


  const fetchVideo = (interval) => {
    let data = {
      videoId: content.campaign.id
    }
    dispatch(onCheckInnerStatus(data, setGenerationText, interval))
  }

  const fetchVideos = () => {
    dispatch(onFetchVideo(id, history, setLoader))
  }

  let div = document.getElementById("beacon-container")

  useEffect(() => {
    if (div) {
      div.style.display = "none"
    }

    return () => {
      if (div) {
        div.style.display = "block"
      }
    }
  }, [div])

  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])

  useEffect(() => {
    fetchVideos()
    return () => {
      dispatch(onUnmountEditor())
    }
  }, [])


  const toggleClass = () => {
    setIsActive(!isActive);
  };


  const togglePanelFunc = (val) => {
    setTogglePanel(val);
  };

  useEffect(() => {
    if (+content.campaign.status === 1) {
      let text = `Generated ${content.campaign.slides.length}/${+content.campaign.totalSlides}`
      setGenerationText(text)
      const interval = setInterval(() => {
        fetchVideo(interval)
      }, 5000)

      return () => {
        clearInterval(interval)
      }
    }

    if (content.campaign) {
      setCampName(content.campaign.name)
    }
  }, [content.campaign])



  const onCheckCloneVoice = (url, interval, index) => {
    let data = {
      url: url
    }

    dispatch(checkCloneVoice(data, index, interval))
  }

  useEffect(() => {
    if (content.campaign)
      if (content.campaign.slides.length > 0) {
        content.campaign.slides.forEach((curElem, index) => {
          if (curElem.audio.status === "processing") {
            const interval = setInterval(() => {
              onCheckCloneVoice(curElem.audio.processData.url, interval, index)
            }, 5000)
          }
        })
      }
  }, [content])




  return (
    loader ?
      <div className="loader-sec">
        <div className="loader">
        </div>
      </div> :
      <>
        <TitleBar title="Editor" />

        <section className="siteWrap for-editor">

          <div className="editor-title-wrap">
            <div className="editor-title">
              <div className="editor-title-left">
                <div className="editor-logo cursor-pointer" onClick={handlePush}><img src={require('../../../src/images/logo-small.png')} /></div>
                <span className="campaignName">
                  <input
                    type="text"
                    placeholder="Instagram Video"
                    value={campName}
                    style={{ width: 400 }}
                    onChange={(e) => setCampName(e.target.value)}
                    onBlur={handleSubmit}
                  />
                </span>
              </div>
              <div className="editor-title-right">
                <button
                  className="demoLink alt cursor-pointer m-0 solid"
                  onClick={() => handlePreview("toggle")}
                  disabled={editor.voiceClone.processLoader || editor.voiceOver.isGenerating}
                  style={{ background: editor.voiceClone.processLoader || editor.voiceOver.isGenerating ? "gray" : "" }}
                >
                  {showPreview ? <>Close <IoClose /></> : <>Preview <IoEyeOutline /></>}
                </button>
                <button
                  className="demoLink alt cursor-pointer m-0"
                  onClick={handleRender}
                  style={{ background: editor.voiceClone.processLoader || editor.voiceOver.isGenerating ? "gray" : "" }}
                  disabled={editor.voiceClone.processLoader || editor.voiceOver.isGenerating}
                >
                  {renderLoader ?
                    <>
                      Rendering
                      <i className="fa fa-spinner fa-spin mx-1" />
                    </> :
                    <>
                      Render
                      <LuVideotape />
                    </>
                  }
                </button>
              </div>
            </div>
          </div>


          <div className={`editorWrap ${togglePanel ? "active" : ""}`}>
            <div className={isActive ? 'editorToggle active' : 'editorToggle'} onClick={toggleClass}><FaAnglesRight /></div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="story">

              <div className={isActive ? 'left-side active' : 'left-side'} style={{ width: togglePanel ? 150 : "" }}>

                <div className="editor-nav">
                  <Nav variant="pillsN">
                    <Nav.Item>
                      <Nav.Link eventKey="story">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><BiBookBookmark /></span>
                          <span className="navTxt">Storyboard</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="text">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><BsTextareaT /></span>
                          <span className="navTxt">Text</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="bgMedia">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><BsImages /></span>
                          <span className="navTxt">Background</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="media">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><GrMultimedia /></span>
                          <span className="navTxt">Media</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="voiceOver">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><MdOutlineRecordVoiceOver /></span>
                          <span className="navTxt">Voiceover</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sadTalker">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><RiUserFill /></span>
                          <span className="navTxt">Talking Head</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item onClick={() => handleActive("music")}>
                      <Nav.Link eventKey="audio">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><HiOutlineMusicNote /></span>
                          <span className="navTxt">Music</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    {memberShip.includes("unlimited") || memberShip.includes("unlimited-lite") || memberShip.includes("bundle") ?
                      <>
                        <Nav.Item onClick={() => handleActive("transition")}>
                          <Nav.Link eventKey="transition">
                            <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                              <span className="navIcon"><PiCubeTransparentLight /></span>
                              <span className="navTxt">Transition</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="animation">
                            <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                              <span className="navIcon"><MdAnimation /></span>
                              <span className="navTxt">Animation</span>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </> :
                      <>
                      </>
                    }
                    <Nav.Item>
                      <Nav.Link eventKey="templates">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><HiTemplate /></span>
                          <span className="navTxt">Templates</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="settings">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><IoSettingsOutline /></span>
                          <span className="navTxt">Settings</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="credits">
                        <div onDoubleClick={() => togglePanelFunc(true)} onClick={() => togglePanelFunc(false)}>
                          <span className="navIcon"><CgFilm /></span>
                          <span className="navTxt">Credits</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <div className="editor-left">

                  <div className="editor-control">
                    <Tab.Content>
                      <Tab.Pane eventKey="story">
                        <Scripts
                          slideData={content.campaign.slides}
                          memberShip={memberShip}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="text">
                        <Text
                          slideData={content.campaign.slides}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="bgMedia">
                        <BackgroundMedia
                          slideData={content.campaign.slides}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="media">
                        <Media
                          slideData={content.campaign.slides}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="voiceOver">
                        <VoiceOver
                          slideData={content.campaign.slides}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="sadTalker">
                        <TalkingHead />
                      </Tab.Pane>

                      <Tab.Pane eventKey="audio">
                        <Audio
                          activeMusic={active.music}
                          slideData={content.campaign.slides}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="settings">
                        <Setting
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="credits">
                        <Credits
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="templates">
                        <Template
                          setLoader={setLoader}
                          setPreLoader={setPreLoader}
                        />
                      </Tab.Pane>

                      {memberShip.includes("unlimited") || memberShip.includes("unlimited-lite") || memberShip.includes("bundle") ?
                        <>
                          <Tab.Pane eventKey="transition">
                            <Transition
                              slideData={content.campaign.slides}
                              transActive={active.transition}
                              memberShip={memberShip}
                            />
                          </Tab.Pane>

                          <Tab.Pane eventKey="animation">
                            <Animation
                              slideData={content.campaign.slides}
                              memberShip={memberShip}
                            />
                          </Tab.Pane>
                        </>
                        :
                        <>
                        </>
                      }
                    </Tab.Content>
                  </div>
                </div>
              </div>
            </Tab.Container>

            <div className="editor-right">
              {/* ------------ Preview --------- */}

              <div className="editorMain">
                <Preview
                  dimension={content.campaign.dimension}
                  campaign={content.campaign}
                  logo={content.campaign.logo}
                  watermark={content.campaign.watermark}
                  preLoader={preLoader}
                  showPreview={showPreview}
                  handlePreview={handlePreview}
                />
              </div>

              <div className="editorReel-main">
                <div className="editorReel-wrap d-flex">
                  <div className="editorReel alt">
                    <VideoTiles
                      slideData={content.campaign.slides}
                      generatingText={generatingText}
                    />
                  </div>

                </div>
              </div>

            </div>
          </div >
        </section >


      </>
  )
}

export default Editor;
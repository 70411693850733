/**
 * @typedef {'modal' | 'screenPreview'} PreviewType
 */

/**
 * @typedef {Object} RemotionProps
 * @property {boolean} show
 * @property {() => void} handleClose   
 * @property {PreviewType} previewType
 */

/**
 * @param {RemotionProps} props
 */
import React, { useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import { Player } from "@remotion/player";
import { useSelector } from 'react-redux';
import RemotionEditor from './RemotionEditor';
import { useRef } from 'react';

const Remotion = ({ show, handleClose, previewType }) => {

    const playerRef = useRef(null);
    const campaign = useSelector(state => state.content.campaign)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        let introDuration = (campaign?.intro?.enable === true && campaign?.intro?.src !== "") ? +campaign.intro.duration * 30 : 0
        let outroDuration = (campaign?.outro?.enable === true && campaign?.outro?.src !== "") ? +campaign.outro.duration * 30 : 0
        let dur = 0 + introDuration + outroDuration
        let audioDelay = 30
        if (campaign.slides?.length > 0) {
            campaign.slides.forEach((element, index) => {
                dur += +element.duration * 30
                if (index === 0) {
                    dur += (campaign?.intro?.enable === true && campaign?.intro?.src !== "") ? audioDelay : 0
                } else if (index === campaign.slides?.length - 1) {
                    // 1 sec extra for proper voice over in the end
                    dur += (campaign?.outro?.enable === true && campaign?.outro?.src !== "") ? 0 : audioDelay
                } else {
                    dur += audioDelay
                }
            });
            setDuration(dur)
        }
    }, [campaign])

    return (
        <>
            {previewType === "modal" ?
                <Modal className={`theme-modal video_player_render ${campaign.dimension === "1920x1080" ? "remotion-preview_16X9 xl" : campaign.dimension === "1080x1920" ? "remotion-preview_9X16" : "remotion-preview_1X1"}`} show={show} onHide={handleClose} centered scrollable>
                    <Modal.Body>
                        <div style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                            display: "flex", justifyContent: "center"
                        }}
                        >
                            <Player
                                ref={playerRef}
                                component={RemotionEditor}
                                inputProps={{
                                    slideData: campaign,
                                    multiplyNum: 1,
                                    playerRef: playerRef,
                                    totalDuration: duration
                                }}
                                durationInFrames={Math.ceil(duration || 30)}
                                compositionWidth={campaign.dimension === "1920x1080" ? 854 : campaign.dimension === "1080x1920" ? 360 : 460}
                                compositionHeight={campaign.dimension === "1920x1080" ? 480 : campaign.dimension === "1080x1920" ? 640 : 460}
                                fps={30}
                                controls={true}
                                style={{ height: "100%", border: "1px solid #201f1f", backgroundColor: "black" }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ borderTop: "1px solid #000" }}>
                        <button className='theme-btn bdr' onClick={handleClose}><span>Cancel</span></button>
                    </Modal.Footer>
                </Modal>
                :
                <div style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    display: "flex", justifyContent: "center"
                }}
                >
                    <Player
                        ref={playerRef}
                        component={RemotionEditor}
                        inputProps={{
                            slideData: campaign,
                            multiplyNum: 1,
                            playerRef: playerRef
                        }}
                        durationInFrames={Math.ceil(duration || 30)}
                        compositionWidth={campaign.dimension === "1920x1080" ? 854 : campaign.dimension === "1080x1920" ? 360 : 460}
                        compositionHeight={campaign.dimension === "1920x1080" ? 480 : campaign.dimension === "1080x1920" ? 640 : 460}
                        fps={30}
                        controls={true}
                        style={{ height: "100%", border: "1px solid #201f1f", backgroundColor: "black" }}
                    />
                </div>
            }
        </>
    )
}

export default Remotion
import React, { useState } from "react";
import LoginFooter from "./LoginFooter";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { resetPassword } from "../../actions/authAction";
import logo from '../../images/LOGO.png';
import TitleBar from "../TitleBar";

const ResetPassword = ({ location }) => {

    const dispatch = useDispatch()
    const history = useHistory();

    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, history));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    return (
        <>

            <TitleBar title="Reset Password" />

            <header className="loginHeader">
                <div className="container">
                    <div className="loginLogo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2>Welcome to <span>ContentReelAI!</span></h2>
                                <p className="text-justify">Transform your video creation process with AI-animated faces, bringing your unique presence to every platform. ContentReelAI - where your digital persona comes to life in engaging and dynamic videos.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={(e) => handleSubmit(e)}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="************"
                                                required
                                                onChange={handleChange}

                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input
                                                type="password"
                                                name="confirm_password"
                                                className="form-control"
                                                placeholder="************"
                                                required
                                                onChange={handleChange}

                                            />
                                        </div>
                                        <p style={{ fontSize: 13, margin: "5px" , color:"#DC143C"}}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary themeBtn" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Login ? <Link to="/login" > Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    )
}


export default ResetPassword;
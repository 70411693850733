import React, { useState, useEffect } from "react";
import videoThumb from '../../images/fav-new.png';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { getContent, onDeleteRecord, onUpdateName, setCampName } from "../../actions/contentAction";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { themeColor } from "../../global/global";
import campThumb from "../../images/campThumb.png"
const VideoCard = ({ setProject, project, showRecord, setTotalCourses, searchKey }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const [loader, setLoader] = useState(false)
    const [nameLoader, setNameLoader] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [button, setButton] = useState('Delete')

    const [selectedId, setSelectedId] = useState({
        id: -1,
        name: ""
    });
    const [nameData, setNameData] = useState({
        cName: "",
        cId: "",
        active: false,
        loader: false,
        index: -1
    })

    const handleShow = (data, index) => {
        setNameData({
            ...nameData,
            cName: data.name,
            cId: data.id,
            active: true,
            index: index
        })
    }

    const handleChange = (e) => {
        setNameData({
            ...nameData,
            cName: e.target.value
        })
    }

    const handleSubmit = (curElem) => {
        if (nameData.cName && curElem.name !== nameData.cName) {
            setNameLoader(true)
            let data, epName
            epName = "update-campaign"
            data = {
                campaignName: nameData.cName,
                campaignId: nameData.cId
            }
            dispatch(onUpdateName(epName, data, fetchContents, setNameLoader, nameData, setNameData))
        }
        else {
            setNameData({
                ...nameData,
                cName: "",
                cId: "",
                active: false,
                index: -1
            })
        }
    }

    const onOpenContent = (curElem) => {
        dispatch(setCampName(curElem))
        history.push(`/video-set`)
    }


    const handleDelete = (id, name) => {
        setSelectedId({
            ...selectedId,
            id: id,
            name: name
        })
        setShowDelete(true)
    }

    const onConfirm = () => {
        let data = {
            campaignId: selectedId.id,
        }
        setButton("Deleting...")
        dispatch(onDeleteRecord(data, setButton, setShowDelete, fetchContents))
    }

    const fetchContents = () => {
        setLoader(true)
        dispatch(getContent(setProject, setLoader, setTotalCourses, showRecord))
    }

    useEffect(() => {
        fetchContents()
    }, [])


    return (
        <>
            <div className="row mt-5 mb-5">
                {project.length > 0 && project.filter((item) => {
                    if (searchKey && !showRecord) {
                        return item.name.toLowerCase().includes(searchKey.toLowerCase())
                    }
                    else {
                        return item
                    }
                }).length > 0 ?
                    project.filter((item) => {
                        if (searchKey && !showRecord) {
                            return item.name.toLowerCase().includes(searchKey.toLowerCase())
                        }
                        else {
                            return item
                        }
                    }).map((curElem, index) => {
                        return (
                            <div className="col-xl-3 col-md-6 col-sm-12" key={curElem.id}>
                                <div className="videoProject-single">
                                    <div className="videoProject-single-top ">
                                        <img src={campThumb} alt="" style={{ width: "100%", height: "100%", overflow: "hidden", objectFit: "cover" }} />
                                        {/* <div className="video-card-wrapper-1 dashboard-card-bg" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <img src={videoThumb} className="mb-2 vidThumb-img  img-fluid" alt="" />
                                        </div> */}

                                        <div className="vidHover">
                                            <div><p>{curElem.created}</p></div>
                                            <div className="vidiconAll">

                                                <div className="vidHover-single cursor-pointer">
                                                    <Link to={`/video-set?id=${curElem.id}`}>
                                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                        <span>Edit</span>
                                                    </Link>
                                                </div>
                                                {auth.user.isClientAccount !== "1" ?
                                                    <div className="vidHover-single cursor-pointer" onClick={() => handleDelete(curElem.id, curElem.name)}>
                                                        <a>
                                                            <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="videoProject-single-bottom">
                                        <div className="videoProject-single-icon">
                                            <div className="single-icon-icon cursor-pointer" onClick={() => handleShow(curElem, index)}>
                                                {nameLoader && index === nameData.index ? <i className="fa fa-spinner fa-spin" /> : <i class="fa fa-pencil-square-o" aria-hidden="true" />}

                                            </div>
                                            <div className="single-icon-txt">
                                                {nameData.active && index === nameData.index ?
                                                    <input
                                                        value={nameData.cName}
                                                        id={index}
                                                        onChange={(e) => handleChange(e)}
                                                        onBlur={() => handleSubmit(curElem)}
                                                    />
                                                    :
                                                    <span>{curElem.name}</span>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                    : !loader ? <div className="col-md-12">
                        <div className="text-center text-muted">
                            {searchKey && project.length > 0 ?
                                <h6>No data found!</h6>
                                :
                                <h6>You do not have any "Campaigns" in your account</h6>
                            }
                        </div>
                    </div> : ''}

                {
                    loader ?
                        <div className="col-md-12 text-center" >
                            <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: "34px" }} />
                        </div> : ''
                }
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                <div style={{ color: "gray", fontWeight: "bold" }}>
                    Deleting the campaign "{selectedId.name}" will delete all video sets of it.
                </div>
            </SweetAlert>
        </>
    )
}

export default VideoCard;
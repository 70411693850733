import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducers from "./reducers";

function saveToLocalStorage(data) {
    const state = JSON.stringify({ auth: data.auth })
    localStorage.setItem('state', state)
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e)
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();

// store is created
const store = createStore(rootReducers,
    persistedState,
    composeWithDevTools(applyMiddleware(thunk))
);

// save state every time when store values changes.
store.subscribe(()=>saveToLocalStorage(store.getState()));
export default store;
import React, { useState } from 'react'
import { Tab, Nav, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { onUploadIR, removeIO, updateIOStatus } from '../../../actions/contentAction';
import sample from "../../../images/sample.png";
import closeModalx from "../../../images/modal-close.png"
import ModalTab from './ModalTab';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';

const IntroOutro = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const social = useSelector(state => state.social)

    const [loader, setLoader] = useState(false)
    const [showDelete, setShowDelete] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleStatus = (e) => {
        dispatch(updateIOStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            dispatch(removeIO(props.type))
        }
    }

    const onInputVideo = (e) => {
        if (e.target.files[0].type === "video/mp4") {
            const allowedSize = 20000000;
            if (e.target.files[0].size < allowedSize) {
                const formData = new FormData();
                setLoader(true)
                formData.append('file', e.target.files[0])
                formData.append('upload_type', props.type)
                dispatch(onUploadIR(formData, setLoader, props.type))
            } else {
                swal("Oops!", "Max allowed size for video is 20MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type ");
        }
    }

    const onConfirmVideoReel = () => {
        history.push("/integration")
    }

    return (
        <>
            <div className="tabInner">
                <div className="media-block">
                    <div className="add-block-single">
                        <div className="add-block-main">
                            <div className="add-block-title  mb-4">
                                <h2 className='text-capitalize'>{props.type}</h2>
                                <div className="switch-single">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={props.data.enable}
                                            onChange={(e) => handleStatus(e)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {props.data.enable ?
                                <>
                                    <div className="fileUpload"
                                        style={props.data.src ? { color: '#CE88FF' } : {}}
                                    >
                                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                                        {
                                            props.data.name ?
                                                props.data.name.length > 23 ? `${props.data.name.slice(0, 23)}...` : props.data.name : `Upload your ${props.type}`}
                                        <input
                                            type="file"
                                            className='cursor-pointer'
                                            onChange={(e) => onInputVideo(e)}
                                        />
                                    </div>
                                    <div className="itemDisp">
                                        <div className="itemDisp-left">
                                            <ul>
                                                <li style={{ position: 'relative' }}>
                                                    {loader ? <i className="fa fa-spinner fa-spin loader-center" />
                                                        :
                                                        <img
                                                            src={props.data.thumbnail ? props.data.thumbnail : sample}
                                                            alt=""
                                                            className="uploadImg"
                                                        />
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='itemDisp-right'>
                                            <button
                                                onClick={handleShow}
                                            >Select {props.type} video +</button>
                                        </div>

                                        <Modal className="VideoModal VideoModal-forintro" show={show} onHide={handleClose} centered>

                                            <Modal.Body style={{ color: '#ffffff' }}>
                                                <div onClick={handleClose} className="vidClose"><img src={closeModalx} alt="" /></div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="tabInner">
                                                            <Tab.Container id="" defaultActiveKey="intro-video">
                                                                <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded {props.type} videos</Nav.Link>
                                                                    </Nav.Item>

                                                                    <Nav.Item>
                                                                        <Nav.Link eventKey="Videoreel-video" style={{ fontSize: '15px' }}>VideoReel {props.type} videos</Nav.Link>
                                                                    </Nav.Item>
                                                                </Nav>

                                                                <Tab.Content>
                                                                    <Tab.Pane eventKey="intro-video">
                                                                        <div className="innertab credit-modal" style={{ paddingRight: '20px' }}>
                                                                            <div className="row">
                                                                                {props.uploadedData.length > 0 ?
                                                                                    props.uploadedData.map((curElem, index) => {
                                                                                        return (
                                                                                            <React.Fragment key={index}>
                                                                                                <ModalTab
                                                                                                    curElem={curElem}
                                                                                                    url={curElem.url}
                                                                                                    thumbnail={curElem.poster}
                                                                                                    index={index}
                                                                                                    type={props.type}
                                                                                                    handleClose={handleClose}
                                                                                                    setLoader={setLoader}
                                                                                                    tabType="upload"
                                                                                                    fetchIR={props.fetchIR}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <div className='text-center' style={{ fontSize: 20, paddingTop: 80, paddingBottom: 80, }}>
                                                                                        You don't have uploaded {props.type} videos
                                                                                    </div>}
                                                                            </div>


                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>

                                                                <Tab.Content>
                                                                    <Tab.Pane eventKey="Videoreel-video">
                                                                        <div className="innertab  credit-modal" style={{ paddingRight: '20px' }}>
                                                                            <div className="row gy-4">
                                                                                {props.videoReel.length > 0 ?
                                                                                    props.videoReel.map((curElem, index) => {
                                                                                        return (
                                                                                            <React.Fragment key={index}>
                                                                                                <ModalTab
                                                                                                    curElem={curElem}
                                                                                                    url={curElem.output_video}
                                                                                                    thumbnail={curElem.preview_image_url}
                                                                                                    index={index}
                                                                                                    type={props.type}
                                                                                                    handleClose={handleClose}
                                                                                                    setLoader={setLoader}
                                                                                                    tabType="videoReel"
                                                                                                    fetchIR={props.fetchIR}
                                                                                                />
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    <div className='text-center pt-5 pb-5'>
                                                                                        {social.videoreel.length > 0 ?
                                                                                            <div>
                                                                                                You haven't created your VideoReel {props.type} videos yet.
                                                                                            </div>
                                                                                            : <div className="alert alert-warning" role="alert" style={{ width: "80%", margin: "0 auto" }}>
                                                                                                You haven't connected your VideoReel account yet.<br /> Click <a className="custom-anchor"
                                                                                                    onClick={() => setShowDelete(true)}
                                                                                                >Here</a> to connect and use those VideoReel {props.type} videos.
                                                                                            </div>}


                                                                                        <SweetAlert
                                                                                            warning
                                                                                            showCancel
                                                                                            confirmBtnText="Yes"
                                                                                            confirmBtnBsStyle="danger"
                                                                                            cancelBtnBsStyle="light"
                                                                                            title="Are you sure?"
                                                                                            onConfirm={() => onConfirmVideoReel()}
                                                                                            onCancel={() => setShowDelete(false)}
                                                                                            focusCancelBtn
                                                                                            show={showDelete}
                                                                                        >
                                                                                            Please note that navigating from this page will take you to the Integrations page.
                                                                                        </SweetAlert>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Tab.Pane>
                                                                </Tab.Content>
                                                            </Tab.Container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>

                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>


                </div >
            </div >

        </>
    )
}

export default IntroOutro
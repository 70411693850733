import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TrainingTitles from "./TrainingTitles";
import queryString from "query-string";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import TitleBar from "../TitleBar";

const TrainingArticles = ({location}) => {

    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.support);
    const articleData2 = useSelector(state => state.support.articles);

    const filterArticle = articleData2.filter(article => article.id === id)[0];
    const filterName = filterArticle.items.filter((id) => +id.id === +art_id)[0];

    return(
        <>
            <TitleBar title="Training"/>
            <Navbar/>

            <section className="siteWrap">
                <div className="tabCont container">
                    <div className="row">
                        <TrainingTitles location={location} id={id} supportData={articleData}  name={filterName}/>

                        <div className="col-lg-9 tabRight">
                            <div className="tabepaneIn forTraining">
                                <h2 className="tabTitle">
                                <Link className="vidoebtn" to="/help-and-support"><span className="mr-1">
                                    <i className="fas fa-arrow-left"></i></span> Back to Video Training
                                </Link>
                                </h2>

                                <div className="tab-content mt-4" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                        {
                                            filterArticle.items.length > 0 ?
                                                filterArticle.items.map((item,index)=>{
                                                    return(
                                                        +item.id === +art_id ?
                                                        <>
                                                        <div className="tabepaneIn-main" key={index}>
                                                           <div className="tabepaneIn-cont tabepaneIn-cont-alt">

                                                           <div className="text-white"
                                                                 dangerouslySetInnerHTML={{
                                                                     __html: item.content
                                                                 }}>
                                                            </div>

                                                           </div>
                                                        </div>
                                                        </>
                                                        : ''
                                                    )
                                                })
                                            : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default TrainingArticles;
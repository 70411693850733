import React from "react";
import mac from "../../images/img1dash.png"
import mac2 from "../../images/img2dash.png"
import mac3 from "../../images/img3dash.png"
import mac4 from "../../images/img4dash.png"
import { useSelector } from "react-redux";
import { appName } from "../../global/global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return(
        <>
            <section className="featureSec">
                    <div className="container-fluid">
                        <div className="featureSec-wrap">
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>AI-Powered Video Creation:</h6>
                                           {/* <h2>Point & Click Software Creates Videos Automatically</h2> */}
                                           <p>{rebrand? rebrand.name : appName } utilizes well-trained AI models to create videos at scale. Simply enter a keyword and choose the video style, and the AI will select multiple trending topics, write scripts, and convert them into a fully animated video with an innovative talking head feature.</p>
                                           {/* <div className="textL">See how you can use {rebrand? rebrand.name : appName } to <span>create your first project</span></div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>Personalized AI Face Animation:</h6>
                                           {/* <h2>Put the FUN Back Into Video Creation</h2> */}
                                           <p>Not only can you create videos effortlessly, but you can also feature your own AI-animated face. With automatic lip-sync, facial expressions, and head movements, your videos will have a personal touch, making your content more engaging and relatable.</p>
                                           {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac2} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>Enhanced Video Editing Experience:</h6>
                                           {/* <h2>User-Friendly Interface. Use Anytime, Anywhere</h2> */}
                                           <p>Benefit from a completely new editor that enhances your video creation process. The intuitive interface and advanced tools make it easy to customize your videos, ensuring they perfectly align with your vision and brand identity.</p>
                                           {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac3} /></div>
                                   </div>
                               </div>
                            </div>
                            <div className="featureSec-single">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="featureSec-left">
                                           <h6>Automated Scheduling and Publishing:</h6>
                                           {/* <h2>User-Friendly Interface. Use Anytime, Anywhere</h2> */}
                                           <p>Schedule your videos effortlessly and showcase all scheduled videos in one place. {rebrand? rebrand.name : appName } allows you to create mass videos automatically and have them published seamlessly on social media, ensuring consistent visibility and engagement across all your platforms.</p>
                                           {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="featureSec-right"><img alt="" src={mac4} /></div>
                                   </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    )
}

export default DashboardAbout;
import React from 'react';
import { Tab, Nav } from "react-bootstrap";
import BgImageMedia from "../backgroundMedia/BgImageMedia"
import BgVideoMedia from "../backgroundMedia/BgVideoMedia"
import Gifs from "../backgroundMedia/Gifs"
import UploadMedia from '../backgroundMedia/UploadMedia';


const Media = ({ slideData }) => {

    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
    const selectedLayer = slideData[selectedSlideIndex].layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = slideData[selectedSlideIndex].layers.findIndex(({ isSelected }) => isSelected === true)

    return (
        <div className="tabInner">
            <div className='tabTitle'>Media</div>
            <div className="media-block">
                <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="image-tab">Images</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="video-tab">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="gif-tab">Gifs</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="image-tab">
                            <BgImageMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="video-tab">
                            <BgVideoMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="gif-tab">
                            <Gifs
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-tab">
                            <UploadMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};



export default Media;

import React, { useState, useEffect } from 'react';
import { baseURL } from '../../../global/global';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import Navbar from '../../Navbar';
import { FiTrash2 } from "react-icons/fi";
import { IoPlay, IoPause } from "react-icons/io5"
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Title from '../../TitleBar';
import { fetchVideosToMerge } from '../../../actions/contentAction';
import ResolutionModal from './ResolutionModal';
import SweetAlert from '../../CommonComponents/SweetAlert';

const ReelMerge = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [videoData, setVideoData] = useState([])
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);

    const [play, setPlay] = useState(false)
    const [loader, setLoader] = useState(({
        dataLoader: false,
        mergeLoader: false
    }));

    const [resolution, setResolution] = useState("1920x1080")
    const [sweet, setSweet] = useState({
        enable: false,
        eventValue: false,
        confirmButtonName: "Change",
    })
    const [mergeData, setMergeData] = useState({
        data: [],
        name: '',
        preview_image: false,
        ratio: '16:9',
        user_id: auth.user.id
    })

    const fetchProject = () => {
        setLoader({
            ...loader,
            dataLoader: true
        })
        dispatch(fetchVideosToMerge(setVideoData, loader, setLoader))
    }

    useEffect(() => {
        
            fetchProject()
      
    }, [])

    const handleMerge = (item) => {
        if (currentSlide.url !== item.videoUrl) {
            let jsonObj = {
                id: item.id,
                name: item.name,
                preview_image: item.thumbnail,
                url: item.videoUrl,
                isSelected: true
            }
            let data = [...mergeVideos]
            data = data.concat(jsonObj)

            if (data.length > 1) {
                const selectedIndex = data.findIndex(({ isSelected }) => isSelected === true)
                data[selectedIndex].isSelected = false
            }
            setCurrentSlide(jsonObj);
            setMergeVideos(data);
        }
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();

        setLoader({
            ...loader,
            mergeLoader: true
        });
        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0]?.preview_image;
        axios({
            method: 'POST',
            url: `${baseURL}create-merge`,
            data: mergeData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                history.push('/campaigns');
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader({
                ...loader,
                mergeLoader: false
            });
        }).catch(err => {
            console.log(err)
            setLoader({
                ...loader,
                mergeLoader: false
            });
        })
    }
    const activeSlide = (video, index) => {
        let data = [...mergeVideos]
        let selectedIndex = data.findIndex(({ isSelected }) => isSelected === true)
        if (selectedIndex !== -1) {
            data[selectedIndex].isSelected = false
            data[index].isSelected = true
        }
        setCurrentSlide(video)
        
    }

    const deleteSlide = (index) => {
        let removeArr = [...mergeVideos];
        removeArr.splice(index, 1);

        setMergeVideos(removeArr);
    }
    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );
        setMergeVideos(items);
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleBack = () => {
        let selectedIndex = mergeVideos.findIndex(({ isSelected }) => isSelected === true)
        let data = [...mergeVideos]
        if (selectedIndex !== -1) {
            if (selectedIndex > 0) {
                data[selectedIndex].isSelected = false
                data[selectedIndex - 1].isSelected = true
                setMergeData(data)
                setCurrentSlide(data[selectedIndex - 1])
            }
        }
    }

    const handleFor = () => {
        let selectedIndex = mergeVideos.findIndex(({ isSelected }) => isSelected === true)
        let data = [...mergeVideos]
        if (selectedIndex !== -1) {
            if (selectedIndex < data.length - 1) {
                data[selectedIndex].isSelected = false
                data[selectedIndex + 1].isSelected = true
                setMergeData(data)
                setCurrentSlide(data[selectedIndex + 1])
            }
        }
    }
    const handleSubmit1 = (e) => {
       
        setSweet({
            ...sweet,
            enable: true,
            eventValue: e.target.value
        })
        setCurrentSlide(false)
    }
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            eventValue: false,
        })
    }

    const performDelete = () => {
        setCurrentSlide(false)
        setResolution(sweet.eventValue)
        setMergeVideos([])
       
        setMergeData({ ...mergeData, ratio: sweet.eventValue === "1920x1080" ? "1920x1080" : sweet.eventValue==="1080x1920"?  "1080x1920": "1080x1080" })
        setSweet({
            ...sweet,
            enable: false,
            eventValue: false,
        })
    }

    return (
        <>
            <Title title="Reel Merge" />
            <Navbar />
           
            <section className="siteWrap reelsitewrap">
                <div className="editorWrap reelmergeWrap">
                    <div className="editor-left">
                        <div className='row '>
                         
                                <div className="inpField">
                                    <div className="col-12 p-2">
                                        <div className="Select_heading text-center">
                                            <h5>Select Resolution</h5>
                                        </div>
                                        <div className="set_resolution text-center mt-3" >
                                            <div className='d-flex justify-content-center' >
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="flexCheckDefault"
                                                        name='resolution'
                                                        checked={resolution === "1920x1080" ? true : false}
                                                        value={"1920x1080"}
                                                        onChange={handleSubmit1}
                                                    />
                                                    <span className="" for="flexCheckDefault">
                                                        16:9
                                                    </span>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="flexCheckChecked"
                                                        name='resolution'
                                                        checked={resolution === "1080x1920" ? true : false}
                                                        value={"1080x1920"}
                                                        onChange={handleSubmit1}
                                                    />
                                                    <span className="" for="flexCheckChecked">
                                                        9:16
                                                    </span>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="flexCheckChecked"
                                                        name='resolution'
                                                        checked={resolution === "1080x1080" ? true : false}
                                                        value={"1080x1080"}
                                                        onChange={handleSubmit1}
                                                    />
                                                    <span className="" for="flexCheckChecked">
                                                        1:1
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            <hr></hr>
                        </div>
                        <div className="row p-2 ">
                            {
                                videoData.length > 0 ?
                                    videoData .filter((elem) => {
                                       
                                        return   resolution === elem.dimension
                                    }).map((curElem, index) => {
                                        return (
                                            <div
                                                className="col-6 cursor-pointer mb-2 px-1 text-center merge-wrap-img"
                                                key={index}
                                                onClick={() => handleMerge(curElem)}
                                                title={curElem.name}
                                            >
                                                <div className="mediaList-single myImage-respo " style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={curElem.thumbnail}
                                                        alt=""
                                                        className="img-fluid"
                                                        style={{ width: "100%", height: "100%" }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : !loader.dataLoader ?
                                        <div className="text-center" style={{ width: '100%' }}>
                                            <h5 className="text-white">No Videos Created</h5>
                                        </div> : ''
                            }
                            {loader.dataLoader ?
                                <div className="col-md-12">
                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div> : ''
                            }

                        </div>
                    </div>
                    <div className="editor-right">
                        <div className="campaignBar">
                            <form method="post" onSubmit={(e) => handleFormSubmit(e)} className="col-md-12 d-flex flex-wrap align-items-center justify-content-center">
                                <div className="">
                                    <input
                                        type="text"
                                        className="inpField"
                                        placeholder="Add Campaign Name"
                                        defaultValue={mergeData.name}
                                        required
                                        onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                    />
                                </div>

                                <button type="submit" className="demoLink" disabled={mergeVideos.length > 1 ? false : true} >
                                    <span className="mr-1">
                                        {loader.mergeLoader ?
                                            <>
                                                Merging
                                                <i className="fa fa-spinner fa-spin mx-1" />
                                            </> :
                                            <>
                                                <i className="fas fa-file-video mx-2" />
                                                Merge

                                            </>
                                        }
                                    </span>

                                </button>
                            </form>
                        </div>

                        <div className='mergeVideo'>
                            <div className="mergeVideo-main">
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={currentSlide.url}
                                    playing={play}
                                    muted={true}
                                    loop={true}
                                />
                            </div>
                            <div className="videoControll">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="playButton text-center">
                                            <i class="fa fa-step-backward playIcon me-2 cursor-pointer" aria-hidden="true" onClick={handleBack}></i>
                                            {!play ?
                                                <IoPlay
                                                    className='playIcon2 cursor-pointer'
                                                    onClick={() => setPlay(true)}
                                                /> :
                                                <IoPause
                                                    className='playIcon2 cursor-pointer'
                                                    onClick={() => setPlay(false)}
                                                />
                                            }
                                            <i class="fa fa-step-forward playIcon ms-2 cursor-pointer" aria-hidden="true" onClick={handleFor}></i>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section >
            {/* Reel Tiles */}
            <section className="slide-sec-merge">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slide-box">
                                <DragDropContext onDragEnd={onSetRecorder}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided) => (
                                            <ul className="d-flex list-inline align-items-center m-0"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    mergeVideos.length > 0 ?
                                                        mergeVideos.map((video, index) => {
                                                            return (
                                                                <Draggable key={index} draggableId={`id_${index}`}
                                                                    index={index}>
                                                                    {(provided) => (
                                                                        <li
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={index}
                                                                            style={{ border: `${video.isSelected === true ? '2px solid #C16BED' : ''}` }}
                                                                        >
                                                                            <div
                                                                                style={{ position: "relative", width: "100%", height: "100%" }}
                                                                                className='box-ar-wrapper'
                                                                            >
                                                                                <img
                                                                                    src={video.preview_image}
                                                                                    alt=""
                                                                                    style={{ height: "100%", width: "100%" }}
                                                                                    className="img-fluid"
                                                                                    onClick={() => activeSlide(video, index)}
                                                                                />
                                                                                <FiTrash2
                                                                                    className="merge-delete"
                                                                                    color='#C16BED'
                                                                                    size={20}
                                                                                    onClick={() => deleteSlide(index)}
                                                                                />
                                                                            </div>

                                                                        </li>
                                                                    )
                                                                    }
                                                                </Draggable>
                                                            )
                                                        })
                                                        : <li></li>
                                                }
                                                {provided.placeholder}
                                            </ul>
                                        )
                                        }
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Reel Tiles End */}
            <SweetAlert
                show={sweet.enable}
                message={"If you change resolution you will lose current data."}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={false}
            />

        </>
    );
}

export default ReelMerge;

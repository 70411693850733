import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { onTemplateFetch, onUpdateTemplate } from '../../../actions/contentAction'
import blank1 from "../../../images/blank1.png"
import blank2 from "../../../images/blank2.png"
import blank3 from "../../../images/blank3.png"
import TemplateVarient from './TemplateVarient'

const Template = ({ setLoader, setPreLoader }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(state => state.auth)
    const campaign = useSelector(state => state.content.campaign)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [memberShip, setMemberShip] = useState([])

    const [temp, setTemp] = useState([])

    const [tempLoader, setTempLoader] = useState(false)


    const fetchTemp = () => {
        if (memberShip.length > 0) {
            setTempLoader(true)
            dispatch(onTemplateFetch(setTemp, setTempLoader, memberShip))
        }
    }

    const handleSelectTemplate = (id) => {
        if (campaign.templateId !== id) {
            let data = {
                videoId: campaign.id,
                newTemplateId: id,
                dimension: campaign.dimension
            }
            dispatch(onUpdateTemplate(data, history, setLoader))
        }
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    useEffect(() => {
        fetchTemp()
    }, [memberShip])


    return (
        <>
            <div className="tabInner">
                <div className='tabTitle'>Templates</div>
                <div className="media-block">

                    <div className="add-block-single">
                        <div className="add-block-main text-center">
                            {/* <div className="add-block-title mb-4">
                                <h2>Templates</h2>
                            </div> */}
                            {!tempLoader ?
                                <ul className='tempList'>
                                    <li
                                        title={"Blank Template"}
                                        style={{ cursor: "pointer", position: "relative", textAlign: "center" }}
                                        onClick={() => handleSelectTemplate("0")}
                                    >
                                        <div className='tempList-single'>
                                            <div className='tempList-img'>
                                                <img src={campaign.dimension === "1920x1080" ? blank1 :
                                                    campaign.dimension === "1080x1920" ? blank2 :
                                                        campaign.dimension === "1080x1080" ? blank3 : ""
                                                } alt={"Blank Template"} /></div>
                                            {"0" === campaign.templateId ?
                                                <div className="slide-active">
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                : ''
                                            }

                                        </div>
                                        <p style={{ color: "#ffffff" }} className="mb-2">Blank Template</p>
                                    </li>
                                    {
                                        temp.length > 0 ?
                                            temp.map((curElem, index) => {
                                                let imageData = campaign.dimension === "1920x1080" ? curElem.previewImage : campaign.dimension === "1080x1080" ? curElem.preview_squre : campaign.dimension === "1080x1920" ? curElem.preview_vertical : ""
                                                return (
                                                    <li
                                                        key={index}
                                                        title={curElem.name}
                                                        style={{ cursor: "pointer", position: "relative", textAlign: "center" }}
                                                        onClick={() => handleSelectTemplate(curElem.id)}
                                                    >
                                                        <div className='tempList-single'>
                                                            <div className='tempList-img'>
                                                                <img src={imageData} alt={curElem.name} /></div>
                                                            {curElem.id === campaign.templateId ?
                                                                <div className="slide-active">
                                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                                        <p style={{ color: "#ffffff", textTransform: "capitalize" }} className="mb-2">{curElem.name}</p>
                                                    </li>
                                                )
                                            }) : ""
                                    }
                                </ul> :
                                <i className="fa fa-spinner fa-spin" style={{ color: "#AC46B4", fontSize: 25 }} />
                            }


                        </div>
                    </div >

                    <TemplateVarient
                        selectedSlide={selectedSlide}
                        selectedSlideIndex={selectedSlideIndex}
                        setPreLoader={setPreLoader}
                    />
                </div>
            </div>
        </>
    )
}

export default Template
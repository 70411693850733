import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import queryString from "query-string";
import { onEditScript, onFetchVideos, onHandleContent, onScriptFetch, onfetchCampaign } from "../../actions/contentAction";
import { BsArrowLeft } from "react-icons/bs";
import { themeColor } from "../../global/global";

const ContentSet = ({ location }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const content = useSelector(state => state.content)

    const { videoId, id, vsid } = queryString.parse(location.search)
    const [videoData, setVideoData] = useState(false)
    const [state, setState] = useState({
        script: "",
        tCode: ""
    })
    const [loader, setLoader] = useState({
        fetch: true,
        saveLoader: false,
        generateLoader: false
    })

    const fetchEditorData = () => {
        dispatch(onScriptFetch(videoId, state, setState, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
        if (name === "script") {
            dispatch(onEditScript(value))
        }
    }

    const handleScript = (type) => {
        let data = {
            type: type,
            script: state.script,
            videoId: videoId,
            vsid: content.campaign.videoSetId
        }
        if (data.script !== "") {
            if (type === "save") {
                setLoader({
                    ...loader,
                    saveLoader: true
                })
            }
            else if (type === "generate-slides") {
                setLoader({
                    ...loader,
                    generateLoader: true
                })
            }
            dispatch(onHandleContent(data, history, loader, setLoader))
        }
    }

    const fetchCampaign = () => {
        let data = {
            campaignId: id
        }
        dispatch(onfetchCampaign(data))
    }

    const fetchVideos = () => {
        let data = {
            videoSetId: vsid,
        }
        dispatch(onFetchVideos(data, setVideoData, setLoader))
    }

    // useEffect(() => {
    //     let arr = state.script.split(".")
    //     if (arr[arr.length - 1] === "") {
    //         setSlideLen(arr.length - 1)
    //     }
    //     else {
    //         setSlideLen(arr.length)
    //     }
    // }, [state.script])

    useEffect(() => {
        if (vsid) {
            fetchVideos()
        }
    }, [vsid])

    useEffect(() => {
        if (id) {
            fetchCampaign()
        }
    }, [id])

    useEffect(() => {
        if (videoId) {
            fetchEditorData()
        }
    }, [videoId])

    return (
        loader.fetch && !videoData ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div> :
            <>
                <TitleBar title="Video Sets" />

                <Navbar />
                <section className="siteWrap">
                    <div className="contWrap">
                        <div className="container">
                            <div className="contWrap-title justify-content-between">
                                <Link to={`/video-list?id=${id}&vsid=${vsid}`} style={{ color: "white", textTransform: "capitalize" }}>
                                    <h2><BsArrowLeft /> {content.createdCamp.campaignName}<span>|</span> {videoData.name} <span>|</span> {videoData.keyword} </h2>
                                </Link>
                                <div>
                                    <button
                                        className="demoLink"
                                        style={{ background: state.script !== "" ? "" : "#979196" }}
                                        onClick={() => handleScript("save")}
                                    >
                                        {
                                            loader.saveLoader ?
                                                <>
                                                    Saving Script <i className="fa fa-spinner fa-spin" />
                                                </> : "Save Script"}
                                    </button>
                                    <button
                                        className="demoLink"
                                        style={{ background: state.script !== "" ? "" : "#979196" }}
                                        onClick={() => handleScript("generate-slides")}
                                        disabled={loader.generateLoader}
                                    >
                                        {loader.generateLoader ?
                                            <>
                                                Converting to Video <i className="fa fa-spinner fa-spin" />
                                            </>
                                            :
                                            "Convert to Video"
                                        }

                                    </button>
                                </div>
                            </div>
                            <div className="contWrap-list">
                                <div className="row justify-content-center">
                                    <div className="col-lg-10">
                                        <div className="contWrap-email">
                                            <div className="contWrap-email-in">
                                                <div className="email-block">
                                                    <div className="email-block-top">
                                                        <p style={{ color: "#9264a9", fontWeight: "bold" }}>Review / Rewrite video script</p>
                                                    </div>
                                                    <textarea
                                                        className="contText"
                                                        name="script"
                                                        value={state.script}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                    {/* <div className="email-block-bottom">
                                                        <p>Slides : {slideLen}</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </>
    )
}

export default ContentSet;
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onEnableCtaData, onUpdateCtaPos } from '../../../actions/contentAction'
import SummerNote from '../../createContent/SummerNote'

const EditorSummerNote = ({ promo }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        promo: {
            position: "",
            data: "",
            enable: false
        }
    })


    const handleEnable = (e) => {
        // setState({
        //     ...state,
        //     promo: {
        //         ...state.promo,
        //         enable: e.target.checked
        //     }
        // })
        dispatch(onEnableCtaData(e.target.checked))
    }

    const handlePos = (val) => {
        // setState({
        //     ...state,
        //     promo: {
        //         ...state.promo,
        //         position: val
        //     }
        // })
        dispatch(onUpdateCtaPos(val))
    }

    useEffect(() => {
        if (promo) {
            setState({
                ...state,
                promo: promo
            })
        }
    }, [promo])

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title  mb-4">
                    <h2>CTA</h2>
                    <div className="switch-single">
                        <label className="switch" htmlFor="cta">
                            <input
                                type="checkbox"
                                checked={state.promo.enable}
                                id="cta"
                                onChange={(e) => handleEnable(e)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {state.promo.enable ?
                    <>
                        <div style={{ color: "#000000" }}>
                            <SummerNote
                                state={state}
                                setState={setState}
                                type="inEditor"
                            />

                            <div className="visual-position mt-2">
                                <div className="visual-position-top">
                                    <div className={`position-single ${state.promo.position === "top-left" ? "active" : ""}`} onClick={() => handlePos("top-left")}></div>
                                    <div className={`position-single ${state.promo.position === "top-center" ? "active" : ""}`} onClick={() => handlePos("top-center")}></div>
                                    <div className={`position-single ${state.promo.position === "top-right" ? "active" : ""}`} onClick={() => handlePos("top-right")}></div>
                                </div>

                                <div className="visual-position-top">
                                    <div className={`position-single ${state.promo.position === "center-left" ? "active" : ""}`} onClick={() => handlePos("center-left")}></div>
                                    <div className={`position-single ${state.promo.position === "center" ? "active" : ""}`} onClick={() => handlePos("center")}></div>
                                    <div className={`position-single ${state.promo.position === "center-right" ? "active" : ""}`} onClick={() => handlePos("center-right")}></div>
                                </div>

                                <div className="visual-position-top">
                                    <div className={`position-single ${state.promo.position === "bottom-left" ? "active" : ""}`} onClick={() => handlePos("bottom-left")}></div>
                                    <div className={`position-single ${state.promo.position === "bottom-center" ? "active" : ""}`} onClick={() => handlePos("bottom-center")}></div>
                                    <div className={`position-single ${state.promo.position === "bottom-right" ? "active" : ""}`} onClick={() => handlePos("bottom-right")}></div>
                                </div>
                            </div>
                            {/* <div className="itemDisp-right mt-2">

                                <select
                                    onChange={(e) => handlePos(e.target.value)}
                                    className='cursor-pointer'
                                    value={state.promo.position}
                                    style={{ width: "130px" }}
                                >
                                    <option value={'top-left'}>Top Left</option>
                                    <option value={'top-center'}>Top Center</option>
                                    <option value={'top-right'}>Top Right</option>
                                    <option value={'bottom-left'}>Bottom Left</option>
                                    <option value={'bottom-center'}>Bottom Center</option>
                                    <option value={'bottom-right'}>Bottom Right</option>
                                </select>
                            </div> */}
                        </div>
                    </>
                    : ''}
            </div>
        </div>
    )
}

export default EditorSummerNote